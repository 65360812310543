import { h, Component, Fragment } from "preact"
import requireProps from "../utilities/requireProps"

const GST_INCLUSIVE_KEY = 'gstInclusive'

class ProductRates extends Component {

  constructor(props) {
    super()

    requireProps(props, [
      'product',
      'productRatesColumns'
    ])

    this.state = {
      product: props.product,
      gstInclusive: false,
    }
  }

  componentDidMount() {
    const gstInclusive = JSON.parse(localStorage.getItem(GST_INCLUSIVE_KEY))
    if (gstInclusive) {
      this.setState({ gstInclusive })
    }
  }

  handleRatesGSTCheckboxChange(e) {
    const gstInclusive = !this.state.gstInclusive
    this.setState({ gstInclusive  })

    localStorage.setItem(GST_INCLUSIVE_KEY, gstInclusive)
  }

  humanizeRate(rate) {
    let val = Number(rate)
    if (this.state.gstInclusive) {
      val *= 1.15
    }
    return `$${val.toFixed(2)}`.replace('.00', '')
  }

  render() {
    let hasRates = false

    return (
      <div class="product-rates-component">

        <div class="product-rates-component__header">
          <h2>Prices {this.state.gstInclusive ? '(including GST)' : '(excluding GST)'}</h2>
        </div>

        <table class="product-rates-component__table">

          {this.props.productRatesColumns
            .filter(c => !!this.props.product[c['name']])
            .map(column => {
              if (this.props.product[column['name']]) {
                hasRates = true
              }
              return (
                <tr>
                  <th>
                    {column['title']}
                  </th>
                  <td>
                    {this.humanizeRate(this.props.product[column['name']])}
                  </td>
                </tr>
              )
            }
          )}

          {!hasRates && (
            <p style="font-size: 1.1em; font-weight: 500">POA</p>
          )}
        </table>

        <div class="product-rates-component_gst-inclusive-form">
          <label htmlFor="gst-inclusive" class="label-checkbox-wrap">
            Show prices inclusive of GST
            <input
              type="checkbox"
              id="gst-inclusive"
              name="gst-inclusive"
              value="1"
              checked={this.state.gstInclusive}
              onChange={this.handleRatesGSTCheckboxChange.bind(this)}
            />
          </label>
        </div>
      </div>
    )
  }

}

export default ProductRates
