import { h, Component } from "preact"
import Flatpickr from 'flatpickr'


const themes = [
  'dark', // nice and dark :-)
  // 'material_blue',
  // 'material_green',
  // 'material_red',
  // 'material_orange',
  'airbnb', // nice (more square) design
  'confetti', // pretty much the Twizel Hire colour
]

const allowedConfig = [
  'onChange',
  'onOpen',
  'onClose',
  'onMonthChange',
  'onYearChange',
  'onReady',
  'onValueUpdate',
  'onDayCreate',

  'minDate',
  'maxDate',
]

class CalendarPicker extends Component {

  constructor(props) {
    super(props)

    this.state = {}

    this.bindNode = node => this.node = node
    this.flatpickr = null
  }

  componentDidMount() {
    const options = {
      onClose: () => {
        this.node.blur && this.node.blur()
      },
      weekNumbers: false,
      disableMobile: true,
      ...this.props.options
    }

    // Add prop allowedConfig to options
    allowedConfig.forEach(option => {
      if (this.props[option]) {
        options[option] = this.props[option]
      }
    })

    this.flatpickr = new Flatpickr(this.node, options)

    if (typeof this.props.setInstanceRef === 'function') {
      this.props.setInstanceRef(this.flatpickr)
    }

    if (this.props.hasOwnProperty('value')) {
      this.flatpickr.setDate(this.props.value, false)
    }
  }

  componentWillUnmount() {
    this.flatpickr.destroy()
  }

  render() {
    const { options, theme, defaultValue, value, ...props } = this.props

    // Don't pass allowedConfig to dom node
    allowedConfig.forEach(hook => {
      delete props[hook]
    })

    return (
      <input
        {...props}
        type="text"
        defaultValue={defaultValue}
        ref={this.bindNode}
      />
    );
  }

}

export default CalendarPicker
