import { h } from "preact"
import { useState, useEffect, useRef } from 'preact/hooks'
import PropTypes from 'prop-types'
import cx from 'classnames'

function SiteNavMenu(props) {

  const [active, setActive] = useState(false)

  const wrapperRef = useRef(null)
  useEffect(() => {
    function handleClickOutside(event) {
      // if (!isOpen) { return }
      if (wrapperRef.current) {
        if (!wrapperRef.current.contains(event.target)) {
          setActive(false)
        } else if (!event.target.getAttribute('data-no-close')) {
          // They didn't click on the menu itself, they must
          // have clicked an item inside the menu, so close it
          setTimeout(() => {
            // The timeout was required to make the
            // onClick() of the menu item still happen
            setActive(false)
          }, 1)
        }
      }
    }

    document.addEventListener("click", handleClickOutside)
    return () => {
      // Cleanup event listener
      document.removeEventListener("click", handleClickOutside)
    }
  }, [wrapperRef])

  return (
    <div className="navMenuContainer" ref={wrapperRef} data-no-close="true">
      <div
        className={cx(props.className, 'navMenuHamburger', {
          active: active,
        })}
        title="Menu"
        role="menubutton"
        aria-haspopup="true"
        aria-controls="twizelhire-main-menu"
        onClick={() => setActive(!active)}
        data-no-close="true">

        <span className="line line1" data-no-close="true"></span>
        <span className="line line2" data-no-close="true"></span>
        <span className="line line3" data-no-close="true"></span>
      </div>
      <ul className={cx("navMenuDropdown", { active: active })}>
        <li role="menuitem"><a href="/">Home</a></li>
        <li role="menuitem"><a href="/categories">Categories</a></li>
        <li role="menuitem"><a href="/products">All Products</a></li>
      </ul>
    </div>
  )
}

SiteNavMenu.propTypes = {
  className: PropTypes.string
}
SiteNavMenu.defaultProps = {}

export default SiteNavMenu
