import { h, Component, Fragment } from "preact"
import cx from 'classnames'

class Dialog extends Component {

  constructor(props) {
    super()
    // this.state = {}

    if (typeof props.open === 'undefined') {
      throw new Error("open:boolean is a requried prop of Dialog")
    }
    if (typeof props.onClose !== 'function') {
      throw new Error("onClose:function is a requried prop of Dialog")
    }
  }

  handleDialogClose() {
    this.props.onClose()
  }

  render(props, state) {
    return (
      <>
        {this.props.open ? (
          <div
            className={cx("tiwzelhire-dialogBackground", props.class)}
            role="dialog"
            aria-labelledby="dialog-title">
            <div
              className="tiwzelhire-dialogCloseBackdrop"
              onClick={this.handleDialogClose.bind(this)}></div>
            <div className="tiwzelhire-dialog">
              <span
                className="tiwzelhire-closeX"
                onClick={this.handleDialogClose.bind(this)}>
                <i className="icon-close1"></i>
              </span>

              {props.children}

            </div>
          </div>
        ) : ''}
      </>
    )
  }

}

export default Dialog
