import formDataToObject from './formDataToObject'
import removeDoubleUrlSlashes from "./removeDoubleUrlSlashes"

class API {

  constructor(config={}) {
    this.apiEndpoint = config.apiEndpoint || ''
  }

  createXHR() {
    let xhr
    if (window.ActiveXObject) {
      try {
        xhr = new ActiveXObject("Microsoft.XMLHTTP")
      } catch (e) {
        console.error(e.message)
        xhr = null
      }
    }
    else {
      xhr = new XMLHttpRequest()
    }
    return xhr
  }

  handleRequest(url, method, data=null) {
    if (this.apiEndpoint) {
      url = removeDoubleUrlSlashes(`${this.apiEndpoint}/${url}`)
    }

    if (data) {
      if (data.constructor == Object) {
        if (data.constructor.name === 'FormData') {
          // Pull out values from form data
          data = formDataToObject(data)
        }
        data = JSON.stringify(this.__transformObjectKeysFromCamelToSnake(data))
      }
    }

    return new Promise((resolve, reject) => {
      let xhr = this.createXHR()
      xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          let status = xhr.status
          let res = resolve(JSON.parse(xhr.responseText))
          if (status === 0 || (status >= 200 && status < 400)) {
            // The request has been completed successfully
            // console.log('res', res)
            resolve(res)
            // xhr.status
            // xhr.response
            // xhr.responseType
          } else {
            // There has been an error with the request!
            reject(res)
          }
        }
      }
      xhr.open(method, url, true)
      xhr.setRequestHeader('Content-type', 'application/json')
      xhr.setRequestHeader('Accept', 'application/json')
      xhr.send(data)
    })
  }

  get(url) {
    return this.handleRequest(url, 'GET')
  }

  post(url, data) {
    return this.handleRequest(url, 'POST', data)
  }

  put(url, data) {
    return this.handleRequest(url, 'PUT', data)
  }

  delete(url, data) {
    return this.handleRequest(url, 'DELETE', data)
  }

  setEndpoint(endpoint) {
    this.apiEndpoint = endpoint
  }

  // Private

  __keyToUnderscore(key) {
    return key.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase()
  }

  __transformObjectKeysFromCamelToSnake(data, depth=2) {
    const newData = {}
    for (let key in data) {
      const snake_key = this.__keyToUnderscore(key)
      newData[snake_key] = data[key]
      if (data[key].constructor == Object && depth > 1) {
        newData[snake_key] = this.__transformObjectKeysFromCamelToSnake(data[key], depth - 1) // stick to one layer deep
      }
    }
    return newData
  }

}

let api = new API()

export default api
