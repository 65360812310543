import { h } from "preact"
import PropTypes from 'prop-types'


function TabPanel(props) {
  return (
    <section id={props.id} className="tab-panel">
      {props.children}
    </section>
  )
}

TabPanel.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default TabPanel
