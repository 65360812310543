
export default function formDataToObject(formData) {

  if (formData && typeof formData === 'object' && formData.constructor.name === 'FormData') {
    const uniqueKeys = [...new Set(formData.keys())]
    const obj = {}
    uniqueKeys.forEach((value, key) => {
      obj[value] = (formData.getAll(value).length > 1) ? formData.getAll(value) : formData.get(value)
    })
    return obj
  }

  throw new Error("utilities/form_data_to_object: First argument must be of type:FormData")
}
