import { h, Component } from "preact"
import Dialog from "./Dialog"
import requireProps from "../utilities/requireProps"
import api from "../utilities/api"
import cx from "classnames"
import ProductAdminTodosEditor from "./ProductAdminTodosEditor"

function naiveRound(num, decimalPlaces = 2) {
  var p = Math.pow(10, decimalPlaces)
  return Math.round(num * p) / p
}

class AdminProductsList extends Component {

  constructor(props) {
    super()

    requireProps(props, [
      'products',
      'apiEndpoint',
      'RATES_COLUMNS',
    ])

    this.orderByValues = {
      default: 'DEFAULT',
      name: 'NAME',
      active: 'ACTIVE',
      inactive: 'INACTIVE',
      category: 'CATEGORY',
      todos: 'TODOS',
      dateAdded: 'DATE_ADDED',
    }

    this.filterOnlyValues = {
      default: 'DEFAULT',
      noPhoto: 'NO_PHOTO',
      featured: 'FEATURED',
    }

    this.state = {
      filter: '',
      orderBy: this.orderByValues.default,
      filterOnly: this.filterOnlyValues.default,
      dialogIsOpen: false,
      todosShowing: false,
      productUpdateError: null,
      productUpdateSuccess: null,
      products: props.products // comes from Rails, so we can change this now
    }

    this.missingAttrCols = [
      ['description', 'Description'],
      ['featureImageUrl150', 'Image'],
      // ['rate4Hours', '4hr Rate'],
      ['rate8Hours', '8hr Rate'],
      ['active', 'Draft'],
    ]
  }

  handleFilterChange(e) {
    this.setState({ filter: e.target.value })
  }

  handleOrderChange(orderBy) {
    let newState = {
      orderBy,
      // Show todo items only when ordering by todos
      todosShowing: orderBy === this.orderByValues.todos,
    }

    this.setState(newState)
  }

  updateProductLocally(id, attrs) {
    const updatedProducts = [...this.state.products]
    for (let i = 0; i < updatedProducts.length; i++) {
      if (updatedProducts[i].id === id) {
        for (const key in attrs) {
          if (Object.hasOwnProperty.call(attrs, key)) {
            updatedProducts[i][key] = attrs[key]
          }
        }
      }
    }
    this.setState({ products: updatedProducts })
  }

  updateProduct(product, attrs, doLocalUpdate=true) {
    this.setState({
      productUpdateError: null,
      productUpdateSuccess: null
    })
    api
      .put(`${this.props.apiEndpoint}/products/${product.id}`, { product: attrs })
      .then(res => {
        this.updateProductLocally(product.id, { productUpdating: false })
        if (res && res.errors) {
          return this.setState({ productUpdateError: res.errors[0] })
        }
        if (res.status_code !== 200) {
          return this.setState({ productUpdateError: `Product didn't save: Status ${res.status_code}` })
        }
        // Success
        if (doLocalUpdate) {
          this.updateProductLocally(product.id, res.pkg)
        }
        this.setState({ productUpdateSuccess: `Product successfully updated` })
      })
    this.updateProductLocally(product.id, { productUpdating: true })
  }

  onImportClick() {
    console.log('onImportClick')
    this.setState({ dialogIsOpen: true })
  }

  handleFilterClear() {
    this.setState({ filter: '' })
  }

  render(props, state) {

    if (!state.products.length) {
      return (
        <p>No products</p>
      )
    }

    let filteredProducts = state.products.filter(p => {
      return !state.filter || (p.name + (p.description || '')).toLowerCase().includes(state.filter.toLowerCase())
    })

    // If an ordering is set
    if (this.state.orderBy !== this.orderByValues.default) {
      filteredProducts.sort((productA, productB) => {
        if (this.state.orderBy === this.orderByValues.name) {
          const nameA = productA.name.toUpperCase()
          const nameB = productB.name.toUpperCase()
          if (nameA < nameB) { return -1 }
          if (nameA > nameB) { return 1 }
        }
        else if (this.state.orderBy === this.orderByValues.category) {
          if (!productA.categories[0] || !productB.categories[0]) { return -1 }
          const categoryNameA = productA.categories[0].name.toUpperCase()
          const categoryNameB = productB.categories[0].name.toUpperCase()
          if (categoryNameA < categoryNameB) { return -1 }
          if (categoryNameA > categoryNameB) { return 1 }
        }
        else if (this.state.orderBy === this.orderByValues.todos) {
          const productATodos = productA.adminTodos ? productA.adminTodos.length : 0
          const productBTodos = productB.adminTodos ? productB.adminTodos.length : 0
          return productBTodos - productATodos
        }
        else if (this.state.orderBy === this.orderByValues.active) {
          return productB.active - productA.active
        }
        else if (this.state.orderBy === this.orderByValues.inactive) {
          return productA.active - productB.active
        }
        else if (this.state.orderBy === this.orderByValues.dateAdded) {
          return productA.createdAt - productB.createdAt
        }
      })
    }

    // If a filter (button) is set
    if (this.state.filterOnly !== this.filterOnlyValues.default) {
      filteredProducts = filteredProducts.filter((product) => {
        if (this.state.filterOnly === this.filterOnlyValues.featured) {
          return !!product.featuredAt
        }
        if (this.state.filterOnly === this.filterOnlyValues.noPhoto) {
          return !product.featureImageUrl150
        }
        return true
      })
    }

    return (
      <div>
        <Dialog
          open={this.state.dialogIsOpen}
          onClose={() => this.setState({ dialogIsOpen: false })}
          class="twizelhire-import-dialog"
        >
          <h1>Import products</h1>
          <p>Please paste in the following format:</p>
          <code class="twizelhire-code import-text">
            Title, Category, Rates(Hourly), 4 Hour, 8 Hour, 24 Hour, Weekly, Notes, Stock Count, Active<br />
            Title, Category, Rates(Hourly), 4 Hour, 8 Hour, 24 Hour, Weekly, Notes, Stock Count, Active<br />
            ...
          </code>
          <form action="/admin/products/import" method="POST">
            <fieldset>
              <textarea
                name="products_csv"
                id="products_csv"
                cols="30" rows="10"
                style="width: 100%"
                placeholder="CSV values..."
                class="import-text"></textarea>
            </fieldset>
            <fieldset>
              <button type="submit" class="btn btn-blue">Import</button>
            </fieldset>
          </form>
        </Dialog>

        <div class="admin-browse-header">
          <h1>Products Admin Dashboard</h1>
          <div class="admin-browse-header-actions">
            <span class="btn btn-grey" onClick={this.onImportClick.bind(this)}>Import</span>
            <a href="/products/new" class="btn btn-grey">+</a>
            <div class="admin-browse-header-actions__filter-wrap">
              <input
                type="text"
                placeholder="Filter..."
                onInput={this.handleFilterChange.bind(this)}
                value={this.state.filter}
              />
              <i class="icon-cross" onClick={this.handleFilterClear.bind(this)}></i>
            </div>
          </div>
        </div>
        {this.state.productUpdateError && (
          <p class="form-error-message">{this.state.productUpdateError}</p>
        )}
        {/* {this.state.productUpdateSuccess && (
          <p class="form-error-success">{this.state.productUpdateSuccess}</p>
        )} */}
        <ul class="admin-browse-list admin-products-list">
          <li class="admin-browse-list__header-item">
            <div class="admin-browse-list__header__labels-spacer"></div>
            <div class="admin-browse-list__header__labels">

              <span class="admin-browse-list__header__label">{filteredProducts.length} Results</span>

              &nbsp;
              &nbsp;

              <span class="admin-browse-list__header__label">Filter only:</span>
              <span
                class={cx('link', { disabled: this.state.filterOnly === this.filterOnlyValues.default })}
                onClick={() => this.setState({ filterOnly: this.filterOnlyValues.default })}>
                None
              </span>
              <span
                class={cx('link', { disabled: this.state.filterOnly === this.filterOnlyValues.noPhoto })}
                onClick={() => this.setState({ filterOnly: this.filterOnlyValues.noPhoto })}>
                No Photo
              </span>
              <span
                class={cx('link', { disabled: this.state.filterOnly === this.filterOnlyValues.featured })}
                onClick={() => this.setState({ filterOnly: this.filterOnlyValues.featured })}>
                Featured
              </span>

              &nbsp;
              &nbsp;

              <span class="admin-browse-list__header__label">Order:</span>
              <span
                class={cx('link', { disabled: this.state.orderBy === this.orderByValues.default })}
                onClick={() => this.handleOrderChange(this.orderByValues.default)}>
                Default
              </span>
              <span
                class={cx('link', { disabled: this.state.orderBy === this.orderByValues.name })}
                onClick={() => this.handleOrderChange(this.orderByValues.name)}>
                Name
              </span>
              <span
                class={cx('link', { disabled: this.state.orderBy === this.orderByValues.todos })}
                onClick={() => this.handleOrderChange(this.orderByValues.todos)}>
                Todos
              </span>
              <span
                class={cx('link', { disabled: this.state.orderBy === this.orderByValues.category })}
                onClick={() => this.handleOrderChange(this.orderByValues.category)}>
                Category
              </span>
              <span
                class={cx('link', { disabled: this.state.orderBy === this.orderByValues.active })}
                onClick={() => this.handleOrderChange(this.orderByValues.active)}>
                Active
              </span>
              <span
                class={cx('link', { disabled: this.state.orderBy === this.orderByValues.inactive })}
                onClick={() => this.handleOrderChange(this.orderByValues.inactive)}>
                Inactive
              </span>
              <span
                class={cx('link', { disabled: this.state.orderBy === this.orderByValues.dateAdded })}
                onClick={() => this.handleOrderChange(this.orderByValues.dateAdded)}>
                Added
              </span>
            </div>
          </li>
          {filteredProducts.map(product => {
            return (
              <li class="admin-browse-list__item">
                <div className="admin-browse-list__item-header">
                  <div class="admin-browse-item__left-column">
                    <a href={`/products/${product.id}`}>
                      <div class="admin-browse-list__img-wrap">
                        {product.featureImageUrl150 ? (
                          <img src={product.featureImageUrl150} />
                        ) : (
                          <i class="icon-images text-grey"></i>
                        )}
                      </div>
                      <span>{product.name}</span>
                    </a>
                    <ul class="admin-browse-item__info">
                      {product.categories && product.categories.length > 0 && (
                        <li>{product.categories[0].name}</li>
                      )}
                      {props.RATES_COLUMNS.map((rate) => {
                        if (!product[rate.name]) { return '' }
                        return (
                          <li class="info-pricing">
                            ${naiveRound(product[rate.name])}/{rate.abrv}
                          </li>
                        )
                      })}
                    </ul>
                    <ul class="admin-browse-item__missing-attrs">
                      {this.missingAttrCols.map(keyValPair => !product[keyValPair[0]] && <li>{keyValPair[1]}</li>)}
                      {product.adminTodos && product.adminTodos.length > 0 && (
                        <li class="missing-attrs__todos">{product.adminTodos.length} TODO{product.adminTodos.length === 1 ? '' : 'S'}</li>
                      )}
                    </ul>
                  </div>
                  <ul class="admin-browse-item__actions">
                    <li>
                      <span onClick={() => this.updateProduct(product, { featuredAt: product.featuredAt ? null : new Date() })} class="link">
                        {product.featuredAt ? 'Unfeatur' : 'Featur'}{product.productUpdating ? 'ing...' : 'e'}
                      </span>
                    </li>
                    <li>
                      <span onClick={() => this.updateProduct(product, { active: !product.active })} class="link">
                        {product.active ? 'Unpublish' : 'Publish'}{product.productUpdating ? 'ing...' : ''}
                      </span>
                    </li>
                    <li>
                      <a href={`/products/${product.id}/edit`} class="link">
                        <i class="icon-edit1"></i>
                      </a>
                    </li>
                  </ul>
                </div>

                {/* Admin todos list/editor */}
                {this.state.todosShowing && (
                  <ProductAdminTodosEditor
                    product={product}
                    apiEndpoint={this.props.apiEndpoint}
                    className={`admin-browse-item__todos-list ${this.state.todosShowing ? 'todos-showing' : ''}`}
                  />
                )}
              </li>
            )
          })}
        </ul>
      </div>
    );
  }

}

export default AdminProductsList
