import { Component, h } from "preact"
import PropTypes from 'prop-types'
import requireProps from "../utilities/requireProps"
import S3Uploader from "../utilities/S3Uploader"
import FileDropper from "./FileDropper"
import ProductImageThumbnails from "./ProductImageThumbnails"
import api from "../utilities/api"


const imageSizesToCreate = [
  [150, .05],
  [300, .1],
  [600, .2],
  [900, .4],
  [1200, .6],
  [1500, .9],
  [1800, 1.2] // 1800px, 1.2MB
]


class ProductImageUploader extends Component {

  constructor(props) {
    super()

    requireProps(props, [
      'product', // object
      'productPhotos', // array
      'apiEndpoint' // string
    ])

    this.s3Uploader = new S3Uploader({
      createSizes: imageSizesToCreate,
      onStatusChange: this.onUploadStatusChange.bind(this),
      onUploadComplete: this.onUploadComplete.bind(this),
      onEnqueueUpload: this.handleUploadQueueChange.bind(this),
      onDenqueueUpload: this.handleUploadQueueChange.bind(this),
      requestPresignEndpoint: `${props.apiEndpoint}/product_photos/presigned-upload-url?product_id=${props.product.id}`
    })

    this.state = {
      uploadQueue: [],
      uploadStatus: null,
      uploadStatusMsg: null,
      savePhotoError: null,
      formError: null,
      product: {...props.product},
      productPhotos: [...props.productPhotos],
    }
  }

  handleUploadQueueChange() {
    this.setState({ uploadQueue: [ ...this.s3Uploader.uploadQueue ] })
  }

  onUploadStatusChange(status, msg) {
    this.setState({ uploadStatus: status })
    this.setState({ uploadStatusMsg: msg })
  }

  onUploadComplete(result) {
    const product_photo = {
      product_id: this.state.product.id,
      width: result.width,
      height: result.height,
      // caption
    }
    imageSizesToCreate.forEach(imageSize => {
      product_photo[`image_url_${imageSize[0]}`] = result.imagesBySize[imageSize[0]]
    })
    api.post(`${this.props.apiEndpoint}/product_photos`, { product_photo })
      .then(
        (res) => {
          if (res && res.errors) {
            return this.setState({ savePhotoError: res.errors[0] })
          }
          if (res.status_code !== 200) {
            return this.setState({ savePhotoError: `ProductPhoto didn't save: Status ${res.status_code}` })
          }
          // Success
          this.setState({
            savePhotoError: null,
            productPhotos: [ ...this.state.productPhotos, res.pkg ]
          })
          this.handleUploadQueueChange()
        })
  }

  handleFilesDrop(files) {
    // TODO:
    //  Currently if multiple is turned off, it doesn't accept any files if multiple are given.
    //  This is okay but we need to show an error message if that's the case

    let imageFiles = []
    files.forEach(file => {
      if (file.type.startsWith("image/")) {
        imageFiles.push(file)
      }
    })

    // Validate files
    if (!imageFiles.length) {
      return this.setState({ error: 'No valid image files found' })
    }
    this.setState({ error: null })
    this.s3Uploader.addFiles(imageFiles)
  }

  handleThumbnailClick(productPhoto) {
    api.post(`/api/products/${this.props.product.id}/set_feature_photo`, { product: {
      product_photo_id: productPhoto.id
    }})
      .then(
        (res) => {
          if (res && res.errors) {
            return this.setState({ formError: res.errors[0] })
          }
          if (res.status_code !== 200) {
            return this.setState({ formError: `Couldn't set photo as feature image: Status ${res.status_code}` })
          }
          // Success
          this.setState({
            formError: null,
            product: {...this.state.product, ...res.pkg}
          })
        })
  }

  handleThumbnailDeleteClick(productPhoto) {
    productPhoto.deleting = true
    this.setState({ formError: null })
    api.delete(`/api/product_photos/${productPhoto.id}`)
      .then(
        (res) => {
          if (res && res.errors) {
            productPhoto.deleting = false
            return this.setState({ formError: res.errors[0] })
          }
          if (res.status_code !== 200) {
            productPhoto.deleting = false
            return this.setState({ formError: `Something went wrong deleting photo: Status ${res.status_code}` })
          }
          // Success
          const newProductPhotos = [...this.state.productPhotos]
          newProductPhotos.splice(newProductPhotos.indexOf(productPhoto), 1)
          this.setState({
            formError: null,
            productPhotos: newProductPhotos
          })
        })
  }

  getFeaturedPhotoId() {
    if (!this.state.product.feature_product_photo_id) { return null }
    for (let i = 0; i < this.state.productPhotos.length; i++) {
      const productPhoto = this.state.productPhotos[i]
      if (this.state.product.feature_product_photo_id === productPhoto.id) {
        return productPhoto.id
      }
    }
    return null
  }

  render() {
    return (
      <div>
        {this.state.uploadStatus && this.state.uploadStatusMsg && (
          <p className={this.state.uploadStatus === 'error' ? 'form-error-message' : 'form-success-message'}>{this.state.uploadStatusMsg}</p>
        )}
        {this.state.savePhotoError && (
          <p className="form-error-message">{this.state.savePhotoError}</p>
        )}
        {this.state.formError && (
          <p className="form-error-message">{this.state.formError}</p>
        )}
        <ProductImageThumbnails
          productPhotos={this.state.productPhotos}
          uploadingFiles={this.state.uploadQueue}
          miniThumbnails={true}
          highlightedImageId={this.getFeaturedPhotoId()}
          onThumbnailClick={this.handleThumbnailClick.bind(this)}
          onThumbnailDeleteClick={this.handleThumbnailDeleteClick.bind(this)}
          childrenThumbnailClass="FileDropper__parent"
        >
          <FileDropper
            class={this.props.dropzoneClass}
            multiple={true}
            onFilesDrop={this.handleFilesDrop.bind(this)}
          />
        </ProductImageThumbnails>
      </div>
    )
  }
}

ProductImageUploader.defaultProps = {}

ProductImageUploader.propTypes = {
  product: PropTypes.object.isRequired,
  productPhotos: PropTypes.array.isRequired,
  apiEndpoint: PropTypes.string.isRequired,
  dropzoneClass: PropTypes.string,
}

export default ProductImageUploader
