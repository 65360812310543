import { Component, h } from "preact"
import PropTypes from 'prop-types'
import requireProps from "../utilities/requireProps"
import S3Uploader from "../utilities/S3Uploader"
import FileDropper from "./FileDropper"
import api from "../utilities/api"
import cx from "classnames"


const imageSizesToCreate = [
  [150, .05],
  [300, .1],
  [600, .2],
  [900, .4],
  [1200, .6],
  [1500, .9],
  [1800, 1.2] // 1800px, 1.2MB
]

class CategoryImageUploader extends Component {

  constructor(props) {
    super()

    requireProps(props, [
      'category', // object
      'apiEndpoint' // string
    ])

    this.s3Uploader = new S3Uploader({
      createSizes: imageSizesToCreate,
      onStatusChange: this.onUploadStatusChange.bind(this),
      onUploadComplete: this.onUploadComplete.bind(this),
      onEnqueueUpload: this.handleUploadQueueChange.bind(this),
      onDenqueueUpload: this.handleUploadQueueChange.bind(this),
      requestPresignEndpoint: `${props.apiEndpoint}/categories/${props.category.id}/presigned-upload-url`
    })

    this.state = {
      uploadQueue: [],
      uploadStatus: null,
      uploadStatusMsg: null,
      uploadingImageSrcLookup: {},
      saveError: null,
      formError: null,
      category: {...props.category},
    }
  }

  setUploadingFilesArray(uploadingFiles) {
    let keysToDelete = Object.keys(this.state.uploadingImageSrcLookup)
    uploadingFiles.forEach(file => {
      if (this.state.uploadingImageSrcLookup[file.name]) {
        // Keep this key around, so remove it from the deletion queue
        keysToDelete.splice(keysToDelete.indexOf(file.name), 1)
      } else {
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          this.setState({
            uploadingImageSrcLookup: {
              ...this.state.uploadingImageSrcLookup,
              [file.name]: reader.result
            }
          })
        }
      }
    })

    // Delete old keys
    if (keysToDelete.length > 0) {
      let newImageSrcLookup = { ...this.state.uploadingImageSrcLookup }
      keysToDelete.forEach(key => delete newImageSrcLookup[key])
      this.setState({ uploadingImageSrcLookup: newImageSrcLookup })
    }
  }

  handleUploadQueueChange() {
    this.setState({ uploadQueue: [ ...this.s3Uploader.uploadQueue ] })
    this.setUploadingFilesArray(this.s3Uploader.uploadQueue)
  }

  onUploadStatusChange(status, msg) {
    this.setState({ uploadStatus: status })
    this.setState({ uploadStatusMsg: msg })
  }

  onUploadComplete(result) {
    const category_edits = {
      image_width: result.width,
      image_height: result.height,
    }
    imageSizesToCreate.forEach(imageSize => {
      category_edits[`image_url_${imageSize[0]}`] = result.imagesBySize[imageSize[0]]
    })
    api.put(`${this.props.apiEndpoint}/categories/${this.state.category.id}`, { category: category_edits })
      .then(
        (res) => {
          if (res && res.errors) {
            return this.setState({ saveError: res.errors[0] })
          }
          if (res.status_code !== 200) {
            return this.setState({ saveError: `Category didn't save: Status ${res.status_code}` })
          }
          // Success
          this.setState({
            saveError: null,
            category: res.pkg
          })
          this.handleUploadQueueChange()
        })
  }

  handleFilesDrop(files) {

    let imageFiles = []
    files.forEach(file => {
      if (file.type.startsWith("image/")) {
        imageFiles.push(file)
      }
    })

    // Validate files
    if (!imageFiles.length) {
      return this.setState({ error: 'No valid image files found' })
    }
    this.setState({ error: null })
    this.s3Uploader.addFiles([imageFiles[0]]) // only add one file
  }

  handleThumbnailDeleteClick(productPhoto) {
    productPhoto.deleting = true
    this.setState({ formError: null })
    api.delete(`/api/product_photos/${productPhoto.id}`)
      .then(
        (res) => {
          if (res && res.errors) {
            productPhoto.deleting = false
            return this.setState({ formError: res.errors[0] })
          }
          if (res.status_code !== 200) {
            productPhoto.deleting = false
            return this.setState({ formError: `Something went wrong deleting photo: Status ${res.status_code}` })
          }
          // Success
          const newProductPhotos = [...this.state.productPhotos]
          newProductPhotos.splice(newProductPhotos.indexOf(productPhoto), 1)
          this.setState({
            formError: null,
            productPhotos: newProductPhotos
          })
        })
  }

  getFeaturedPhotoId() {
    if (!this.state.product.feature_product_photo_id) { return null }
    for (let i = 0; i < this.state.productPhotos.length; i++) {
      const productPhoto = this.state.productPhotos[i]
      if (this.state.product.feature_product_photo_id === productPhoto.id) {
        return productPhoto.id
      }
    }
    return null
  }

  getCategoryPhotoObject() {
    { categ }
  }

  render() {
    let thumbnailItemClass = "product__photo_thumbnails__item"
    const spinner = (<i className="product__photo_thumbnails__item__spinner icon-spinner9 animate-spin"></i>)
    return (
      <div>
        {this.state.uploadStatus && this.state.uploadStatusMsg && (
          <p className={this.state.uploadStatus === 'error' ? 'form-error-message' : 'form-success-message'}>{this.state.uploadStatusMsg}</p>
        )}
        {this.state.saveError && (
          <p className="form-error-message">{this.state.saveError}</p>
        )}
        {this.state.formError && (
          <p className="form-error-message">{this.state.formError}</p>
        )}

        <ul class={cx("product__photo_thumbnails", { ["product__photo_thumbnails_small"]: this.props.miniThumbnails })}>
          <li class={thumbnailItemClass}>
            <img src={this.state.category.image_url_150} />
            {spinner}
          </li>
          {Object.keys(this.state.uploadingImageSrcLookup).map(key => (
            <li class={cx(thumbnailItemClass, {
              uploading: !!this.state.uploadingImageSrcLookup[key]
            })}>
              <img src={this.state.uploadingImageSrcLookup[key]} />
              {spinner}
            </li>
          ))}
          <FileDropper
            class={this.props.dropzoneClass}
            multiple={true}
            onFilesDrop={this.handleFilesDrop.bind(this)}
          />
        </ul>
      </div>
    )
  }
}

CategoryImageUploader.defaultProps = {}

CategoryImageUploader.propTypes = {
  product: PropTypes.object.isRequired,
  productPhotos: PropTypes.array.isRequired,
  apiEndpoint: PropTypes.string.isRequired,
  dropzoneClass: PropTypes.string,
}

export default CategoryImageUploader
