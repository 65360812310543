import { h, Fragment } from "preact"
import { useState } from "preact/hooks"
import PropTypes from "prop-types"
import css from "./TagsInput.module.scss"

function TagsInput(props) {
  const [tags, setTags] = useState(props.defaultTags)

  let tagInputRef

  function removeTag(i) {
    const newTags = [...tags]
    const removedTag = newTags.splice(i, 1)[0]
    props.onTagRemove(removedTag)

    // Calling setTags will replace tags with the new value
    setTags(newTags)
  }

  function addTag(tag) {
    const addedTag = props.tagNameSetter(tag)
    setTags([...tags, addedTag])
    props.onTagAdd(addedTag)
  }

  function inputKeyDown(e) {
    const val = e.target.value
    if (e.key === 'Enter' && val) {
      e.preventDefault() // Stop a parent form submitting
      if (tags.find(tag => props.tagNameGetter(tag).toLowerCase() === val.toLowerCase())) {
        return // Tag already exists in list
      }
      addTag(val)
      tagInputRef.value = null
    } else if (e.key === 'Backspace' && !val) {
      removeTag(tags.length - 1)
    }
  }

  function handleRemoveTag(e, tagIndex) {
    e.preventDefault()
    removeTag(tagIndex)
  }

  return (
    <div className={css.inputTag}>
      <ul className={css.tagsList}>
        {tags.map((tag, i) => (
          <li key={tag} class={props.tagClassSetter(tag)}>
            {props.tagNameGetter(tag)}
            <button type="button" onClick={e => handleRemoveTag(e, i)}>
              <i className="icon-cancel"></i>
            </button>
          </li>
        ))}
        <li className={css.tagsListInputWrapper}>
          <input
            type="text"
            onKeyDown={inputKeyDown}
            placeholder={this.props.placeholder}
            autocorrect="off"
            autocapitalize="none"
            ref={el => { tagInputRef = el }}
          />
        </li>
      </ul>
    </div>
  )
}

TagsInput.propTypes = {
  placeholder: PropTypes.string,
  onTagAdd: PropTypes.func.isRequired,
  onTagRemove: PropTypes.func.isRequired,
  tagNameSetter: PropTypes.func.isRequired,
  tagNameGetter: PropTypes.func.isRequired,
  tagClassSetter: PropTypes.func,
}

TagsInput.defaultProps = {
  defaultTags: [],
  tagNameSetter: (tagName) => tagName,
  tagNameGetter: (tag) => tag,
  tagClassSetter: (adminTag) => '',
}

export default TagsInput
