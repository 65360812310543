import { h, Component, Fragment } from "preact"
import api from "../utilities/api"
import requireProps from "../utilities/requireProps"
import TagsInput from "./TagsInput"
import cx from "classnames"


class ProductAdminTagsInput extends Component {

  constructor(props) {
    super()

    requireProps(props, [
      'productId',
      'productAdminTags',
      'adminTags',
      'apiEndpoint'
    ])

    this.state = {
      adminTags: props.adminTags,
      productAdminTags: props.productAdminTags,
      loading: false,
      error: null,
      success: null,
      shouldDeleteLast: false
    }

    this._clearMsgTimeout = null
  }

  handleShouldDeleteLastChange(e) {
    const { checked } = e.target
    this.setState({ shouldDeleteLast: checked })
  }

  handleTagAdd(tagName) {
    // Make the API call
    this.setState({ error: null, loading: true })
    api
      .post(`product_admin_tags`, {
        product_admin_tag: {
          tag_name: tagName,
          product_id: this.props.productId,
        }
      })
      .then(res => {
        this.setState({ loading: false })
        if (res && res.errors) {
          return this.setState({ error: res.errors[0] })
        }
        if (res.status_code !== 200) {
          return this.setState({ error: `ProductAdminTag wasn't created: Status ${res.status_code}` })
        }
        // Success
        const updatedAdminTags = this.state.adminTags
        if (!this.state.adminTags.find(at => at.id === res.pkg.adminTag.id)) {
          // Append new admin tag only if it doesn't already exist in list
          updatedAdminTags.push(res.pkg.adminTag)
        }
        this.setState({
          productAdminTags: [
            ...this.state.productAdminTags,
            res.pkg
          ],
          adminTags: updatedAdminTags,
          success: `Tag added successfully`,
        })

        // Clear messages after awhile
        this._clearMsgTimeout = setTimeout(() => {
          if (!this._mounted) { return }
          this.setState({ error: null, success: null })
        }, 4000)
      })
  }

  handleTagRemove(tagName) {
    // Set initial state for this method
    this.setState({
      loading: null,
      error: null,
      success: null
    })
    if (this._clearMsgTimeout) { clearTimeout(this._clearMsgTimeout) }

    // Find the adminTag & productAdminTag
    const adminTag = this.state.adminTags.find(at => at.name === tagName)
    if (!adminTag) { return this.setState({ error: "No AdminTag found with that name" }) }
    const productAdminTag = this.state.productAdminTags.find(pat => pat.adminTagId === adminTag.id)
    if (!productAdminTag) { return this.setState({ error: "No tag found with that name" }) }

    // Make the API call
    this.setState({ loading: true })
    api
      .delete(`${this.props.apiEndpoint}/product_admin_tags/${productAdminTag.id}`, {
        delete_last: this.state.shouldDeleteLast
      })
      .then(res => {
        this.setState({ loading: false })
        if (res && res.errors) {
          return this.setState({ error: res.errors[0] })
        }
        if (res.status_code !== 200) {
          return this.setState({ error: `ProductAdminTag didn't delete: Status ${res.status_code}` })
        }

        let updatedAdminTags = this.state.adminTags
        if (res.pkg && res.pkg.deleted_last) {
          updatedAdminTags = this.state.adminTags.filter(at => at.id !== productAdminTag.adminTag.id)
        }

        // Success
        this.setState({
          adminTags: updatedAdminTags,
          productAdminTags: this.state.productAdminTags.filter(pat => pat.id !== productAdminTag.id),
          success: `Tag removed successfully`,
        })

        // Clear messages after awhile
        this._clearMsgTimeout = setTimeout(() => {
          if (!this._mounted) { return }
          this.setState({ error: null, success: null })
        }, 4000)
      })
  }

  componentDidMount() {
    this._mounted = true
  }

  componentWillUnmount() {
    this._mounted = false
  }

  render(props, state) {
    const defaultTags = this.state.productAdminTags.map(pat => pat.adminTag.name)

    return (
      <div class="product-admin-tags-input">
        <TagsInput
          onTagAdd={this.handleTagAdd.bind(this)}
          onTagRemove={this.handleTagRemove.bind(this)}
          defaultTags={defaultTags}
          key={`tags-input-length-${defaultTags.length}`} // wasn't updating without this
        />

        {this.state.loading && (<p class="form-msg form-loading">Loading...</p>)}
        {this.state.error && (<p class="form-msg form-error-message">{this.state.error}</p>)}
        {this.state.success && (<p class="form-msg form-success-message">{this.state.success}</p>)}

        <label class="label-checkbox-wrap">
          <input
            type="checkbox"
            onChange={this.handleShouldDeleteLastChange.bind(this)}
            defaultChecked={this.state.shouldDeleteLast}
          />
          Delete AdminTag (if last)
        </label>

        <ul class="admin-tags-list">
          {this.state.adminTags.map(adminTag => (
            <li
              onClick={e => this.handleTagAdd(adminTag.name)}
              class={cx({
                used: this.state.adminTags.find(at => at.id === adminTag.id)
              })}
            >
              {/* TODO: Maybe we allow deleting of tags from here?? */}
              {adminTag.name}
            </li>
          ))}
        </ul>
      </div>
    )
  }

}

export default ProductAdminTagsInput
