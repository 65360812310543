import { h, Component, Fragment } from "preact"
import PropTypes from "prop-types"
import requireProps from "../utilities/requireProps"
import cx from "classnames"
import css from "./AdminTagsPickerList.module.scss"


function AdminTagsPickerList(props) {

  requireProps(props, [
    'adminTags',
  ])

  const handleTagClick = typeof props.onTagClick === 'function' ? props.onTagClick : () => {}

  this.state = {}

  return (
    <ul class={css.adminTagsPickerList}>
      {props.adminTags.map(adminTag => (
        <li
          class={cx(css.tagItem, props.tagClassSetter(adminTag), {
            [css.hoverable]: typeof props.onTagClick === 'function'
          })}
          onClick={() => handleTagClick(adminTag)}
        >{adminTag.name}</li>
      ))}
    </ul>
  )
}

AdminTagsPickerList.propTypes = {
  adminTags: PropTypes.array.isRequired,
  onTagClick: PropTypes.func,
  tagClassSetter: PropTypes.func,
}

AdminTagsPickerList.defaultProps = {
  tagClassSetter: (adminTag) => '',
}

export default AdminTagsPickerList
