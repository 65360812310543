import { h, Component, Fragment } from "preact"
import api from "../utilities/api"
import requireProps from "../utilities/requireProps"
import cx from "classnames"


class ProductToggleActions extends Component {

  constructor(props) {
    super()

    requireProps(props, [
      'product',
      'apiEndpoint'
    ])

    this.state = {
      product: props.product,
      productUpdating: false,
      productUpdateError: null,
      productUpdateSuccess: null,
      productUpdatingAttrs: null
    }

    this._clearMsgTimeout = null
  }

  updateProduct(attrs) {
    this.setState({
      productUpdateError: null,
      productUpdateSuccess: null,
    })
    api
      .put(`${this.props.apiEndpoint}/products/${this.state.product.id}`, { product: attrs })
      .then(res => {
        this.setState({
          product: res.pkg,
          productUpdating: false
        })
        if (res && res.errors) {
          return this.setState({ productUpdateError: res.errors[0] })
        }
        if (res.status_code !== 200) {
          return this.setState({ productUpdateError: `Product didn't save: Status ${res.status_code}` })
        }
        // Success
        this.setState({
          product: res.pkg,
          productUpdateSuccess: `Product successfully updated`,
          productUpdatingAttrs: null
        })
      })
    this.setState({ productUpdating: true, productUpdatingAttrs: attrs })
  }

  componentDidMount() {
    this._mounted = true
  }

  componentWillUnmount() {
    this._mounted = false
  }

  isLoadingForAttribute(attr) {
    return this.state.productUpdating && typeof this.state.productUpdatingAttrs[attr] !== 'undefined'
  }

  render(props, state) {
    return (
      <>
        <span
          onClick={() => this.updateProduct({ featured_at: state.product.featuredAt ? null : new Date() })}
          class="link">
          {state.product.featuredAt ? 'Unfeatur' : 'Featur'}{this.isLoadingForAttribute('featured_at') ? 'ing... ' : 'e '}
        </span>
        <span class="link-color">
          &sdot;
        </span>
        <span
          onClick={() => this.updateProduct({ active: !state.product.active })}
          class="link">
          {state.product.active ? 'Unpublish' : 'Publish'}{this.isLoadingForAttribute('active') ? 'ing... ' : ' '}
        </span>
        <span class="link-color">
          &sdot;
        </span>
        <span
          onClick={() => this.updateProduct({ archived_at: state.product.archivedAt ? null : new Date()  })}
          class="link">
          {state.product.archivedAt ? 'Unarchiv' : 'Archiv'}{this.isLoadingForAttribute('archived_at') ? 'ing... ' : 'e '}
        </span>
        {state.productUpdateError && (
          <span class="link-color">
            &sdot; {state.productUpdateError}
          </span>
        )}
        {state.productUpdateSuccess && (
          <span class="link-color">
            &sdot; {state.productUpdateSuccess}
          </span>
        )}
        {props.appendDot && (
          <span class="link-color">
            &sdot;
          </span>
        )}
      </>
    )
  }

}

export default ProductToggleActions
