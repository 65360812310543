
// Appends a timestamp to the image filename
// to prevent duplicate images clashing on s3
export default function getS3FilenameFromName(originalFilename, append='') {
  const imgFilenameSegments = originalFilename.split('.')
  if (!imgFilenameSegments.length) {
    return originalFilename
  }
  if (append) {
    // Add the append right before the file extension (.jpp, .png, etc)
    imgFilenameSegments.splice(imgFilenameSegments.length - 1, 0, append)
  }
  const firstSegment = imgFilenameSegments.shift()
  return `${firstSegment}-${Date.now()}${imgFilenameSegments.length ? '.' : ''}${imgFilenameSegments.join('.')}`
}
