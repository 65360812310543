import { Component, h } from "preact"
import PropTypes from 'prop-types'
import requireProps from '../utilities/requireProps'
import cx from "classnames"


class ProductImageThumbnails extends Component {

  constructor(props) {
    super()

    requireProps(props, [
      'productPhotos'
    ])

    this.thumbnailItemClass = "product__photo_thumbnails__item"

    this.state = {
      uploadingImageSrcLookup: {}
    }

    this.setUploadingFilesArray(props.uploadingFiles)
  }

  setUploadingFilesArray(uploadingFiles) {
    let keysToDelete = Object.keys(this.state.uploadingImageSrcLookup)
    uploadingFiles.forEach(file => {
      if (this.state.uploadingImageSrcLookup[file.name]) {
        // Keep this key around, so remove it from the deletion queue
        keysToDelete.splice(keysToDelete.indexOf(file.name), 1)
      } else {
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          this.setState({
            uploadingImageSrcLookup: {
              ...this.state.uploadingImageSrcLookup,
              [file.name]: reader.result
            }
          })
        }
      }
    })

    // Delete old keys
    if (keysToDelete.length > 0) {
      let newImageSrcLookup = { ...this.state.uploadingImageSrcLookup }
      keysToDelete.forEach(key => delete newImageSrcLookup[key])
      this.setState({ uploadingImageSrcLookup: newImageSrcLookup })
    }
  }

  handleThumbnailClick(photo) {
    if (typeof this.props.onThumbnailClick === 'function') {
      this.props.onThumbnailClick(photo)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.uploadingFiles.toString() !== this.props.uploadingFiles.toString()) {
      this.setUploadingFilesArray(this.props.uploadingFiles)
    }
  }

  render(props) {
    const hoverable = typeof this.props.onThumbnailClick === 'function'
    const spinner = (<i className="product__photo_thumbnails__item__spinner icon-spinner9 animate-spin"></i>)
    return (
      <ul class={cx("product__photo_thumbnails", { ["product__photo_thumbnails_small"]: props.miniThumbnails })}>
        {props.productPhotos.map(photo => (
          <li class={cx(this.thumbnailItemClass, {
            highlighted: props.highlightedImageId && props.highlightedImageId === photo.id,
            deleting: !!photo.deleting
          })}>
            <img src={photo.imageUrl150} alt={photo.caption} />
            {hoverable && (
              <div
                class="product__photo_thumbnails__item__hoverer"
                onClick={() => this.handleThumbnailClick(photo)}></div>
            )}
            {typeof this.props.onThumbnailDeleteClick === 'function' && (
              <i className="product__photo_thumbnails__item__delete icon-cross" onClick={() => this.props.onThumbnailDeleteClick(photo)}></i>
            )}
            {spinner}
          </li>
        ))}
        {Object.keys(this.state.uploadingImageSrcLookup).map(key => (
          <li class={cx(this.thumbnailItemClass, {
            uploading: !!this.state.uploadingImageSrcLookup[key]
          })}>
            <img src={this.state.uploadingImageSrcLookup[key]} />
            {spinner}
          </li>
        ))}
        {props.children ? (
          <li class={cx(this.thumbnailItemClass, props.childrenThumbnailClass)}>
            {props.children}
          </li>
        ) : ''}
      </ul>
    )
  }
}

ProductImageThumbnails.defaultProps = {
  miniThumbnails: false,
  uploadingFiles: [],
}

ProductImageThumbnails.propTypes = {
  productPhotos: PropTypes.array.isRequired,
  highlightedImageId: PropTypes.number,
  childrenThumbnailClass: PropTypes.string,
  uploadingFiles: PropTypes.array, // array of Html File Elements
  miniThumbnails: PropTypes.bool,
  onThumbnailClick: PropTypes.func,
  onThumbnailDeleteClick: PropTypes.func,
}

export default ProductImageThumbnails
