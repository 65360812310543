import { h, Component, Fragment } from "preact"
import requireProps from "../utilities/requireProps"
import api from "../utilities/api"
import cx from "classnames"
import ListInput from "./ListInput"
import ProductListMinimal from "./ProductListMinimal"
import css from "./AdminTagsEditor.module.scss"

class AdminTagsEditor extends Component {

  constructor(props) {
    super()

    requireProps(props, [
      'adminTags',
      'apiEndpoint'
    ])

    api.setEndpoint(props.apiEndpoint)

    this.state = {
      adminTags: props.adminTags,
      loading: false,
      updateError: null,
      updateSuccess: null,
      createError: null,
      createSuccess: null,
      newTagName: null,
      editingAdminTagId: null,
    }
  }

  async deleteAdminTag(adminTag) {
    this.setState({
      updateError: null,
      updateError: null
    })
    if (this._clearMsgTimeout) { clearTimeout(this._clearMsgTimeout) }
    let res
    try {
      res = await api.delete(`admin_tags/${adminTag.id}`)
      if (res && res.errors) {
        return this.setState({ updateError: res.errors[0] })
      }
      if (res.status_code !== 200) {
        return this.setState({ updateError: `AdminTag didn't delete: Status ${res.status_code}` })
      }
    } catch (e) {
      console.error(`AdminTag failed to delete, error:`, e)
      return this.setState({ updateError: `AdminTag didn't delete: Status ${e.toString()}` })
    }
    // Success
    this.setState({
      updateSuccess: `AdminTag deleted`,
      adminTags: [ ...this.state.adminTags ].filter((at) => {
        return at.id !== adminTag.id
      })
    })

    this._clearMsgTimeout = setTimeout(() => {
      if (!this._mounted) { return }
      this.setState({ updateSuccess: null })
    }, 4000)
  }

  async updateAdminTag(adminTag, attrs, closeEditingOnSave=true) {
    this.setState({
      updateError: null,
      success: null
    })
    if (this._clearMsgTimeout) { clearTimeout(this._clearMsgTimeout) }
    let res
    try {
      res = await api.put(`admin_tags/${adminTag.id}`, {
        adminTag: attrs
      })
      if (res && res.errors) {
        return this.setState({ updateError: res.errors[0] })
      }
      if (res.status_code !== 200) {
        return this.setState({ updateError: `AdminTag didn't update: Status ${res.status_code}` })
      }
    } catch (e) {
      console.error(`AdminTag failed to update, error:`, e)
      return this.setState({ updateError: `AdminTag didn't update: Status ${e.toString()}` })
    }
    // Success
    const updatedAdminTags = [ ...this.state.adminTags ]
    updatedAdminTags.forEach((at, index) => {
      if (at.id === adminTag.id) {
        updatedAdminTags[index] = res.pkg
      }
    })
    this.setState({
      updateSuccess: `AdminTag updated`,
      adminTags: updatedAdminTags,
      editingAdminTagId: closeEditingOnSave ? null : this.state.editingAdminTagId,
    })

    this._clearMsgTimeout = setTimeout(() => {
      if (!this._mounted) { return }
      this.setState({ updateSuccess: null })
    }, 4000)
  }

  async createAdminTag(tagName) {
    this.setState({
      createError: null,
      createSuccess: null
    })
    if (this._clearMsgTimeout) { clearTimeout(this._clearMsgTimeout) }
    let res
    try {
      res = await api.post('admin_tags', {
        adminTag: { name: tagName }
      })
      if (res && res.errors) {
        return this.setState({ createError: res.errors[0] })
      }
      if (res.status_code !== 200) {
        return this.setState({ createError: `New AdminTag failed to save: Status ${res.status_code}` })
      }
    } catch (e) {
      console.error(`New AdminTag failed to save, error:`, e)
      return this.setState({ createError: `New AdminTag failed to save: Status ${e.toString()}` })
    }
    // Success
    this.setState({
      createSuccess: `AdminTag updated`,
      adminTags: [...this.state.adminTags, res.pkg],
      editingAdminTagId: null,
    })
    this.createTagInputRef.value = null

    this._clearMsgTimeout = setTimeout(() => {
      if (!this._mounted) { return }
      this.setState({ createSuccess: null })
    }, 4000)
  }

  async handleUpdateAdminTagInputKeyDown(e, adminTag) {
    if (e.key === 'Enter' && e.target.value) {
      e.preventDefault() // Stop any parent form submitting
      this.updateTagInputRef.value = e.target.value
      await this.updateAdminTag(adminTag, { name: e.target.value })
    }
    else if (e.key === 'Escape') {
      this.setState({ editingAdminTagId: null })
    }
  }

  async handleCreateTagInputKeyDown(e) {
    if (e.key === 'Enter' && e.target.value) {
      e.preventDefault() // Stop any parent form submitting
      this.createTagInputRef.value = e.target.value
      await this.createAdminTag(e.target.value)
    }
  }

  async handleDraftMessagesUpdate(adminTag, list) {
    await this.updateAdminTag(adminTag, {
      draftMessages: JSON.stringify(list)
    }, false) // closeEditingOnSave=false
  }

  handleAdminTagClick(adminTag) {
    if (this.state.editingAdminTagId === adminTag.id) {
      // Toggle off
      return this.setState({ editingAdminTagId: null })
    }
    // Toggle on
    this.setState({ editingAdminTagId: adminTag.id }, () => {
      if (this._mounted && this.updateTagInputRef) {
        this.updateTagInputRef.focus()
      }
    })
  }

  handleAdminTagRemoveClick(adminTag) {
    if (
      adminTag.products.length === 0 ||
      confirm(`Are you sure you want to delete '${adminTag.name}' and it's ${adminTag.products.length} associated ProductAdminTags?`)
    ) {
      this.deleteAdminTag(adminTag)
    }
  }

  componentDidMount() {
    this._mounted = true
  }

  componentWillUnmount() {
    this._mounted = false
  }

  render(props, state) {
    return (
      <div class={css.adminTagsEditor}>
        <ul class={css.adminTagsEditorList}>
          {state.adminTags.map(adminTag => (
            <li
              class={cx({
                [css.editing]: state.editingAdminTagId === adminTag.id
              })}
            >
              <i
                class={cx('icon-chevron-right', css.chevron)}
                onClick={() => this.handleAdminTagClick(adminTag)}
              ></i>
              {state.editingAdminTagId !== adminTag.id ? (
                <>
                  <div
                    class={css.tagName}
                    onClick={() => this.handleAdminTagClick(adminTag)}
                  >
                    {adminTag.name}
                    ({adminTag.products.length})
                  </div>
                  <i
                    class={cx('icon-cross', css.removeTag)}
                    onClick={() => this.handleAdminTagRemoveClick(adminTag)}
                  ></i>
                </>
              ) : (
                <div class={css.updateTagInputWrap}>
                  <input
                    type="text"
                    value={adminTag.name}
                    class="input-block"
                    onKeyDown={(e) => this.handleUpdateAdminTagInputKeyDown(e, adminTag)}
                    autocorrect="off"
                    autocapitalize="none"
                    ref={el => { this.updateTagInputRef = el }}
                  />
                  {state.updateSuccess && (
                    <p className="form-success-message">{state.updateSuccess}</p>
                  )}
                  {state.updateError && (
                    <p className="form-error-message">{state.updateError}</p>
                  )}
                  {adminTag.products.length > 0 && (
                    <ProductListMinimal products={adminTag.products} class={css.productsList} />
                  )}
                  <div class={css.draftMessages}>
                    <h4>Draft messages</h4>
                    <ListInput
                      class={css.draftListInput}
                      placeholder="New message"
                      isKeyValueList={false}
                      inputType="textarea"
                      defaultValues={adminTag.draftMessages ? adminTag.draftMessages : null}
                      onItemAdd={(item, list) => this.handleDraftMessagesUpdate(adminTag, list)}
                      onItemUpdate={(item, list) => this.handleDraftMessagesUpdate(adminTag, list)}
                      onItemRemove={(item, list) => this.handleDraftMessagesUpdate(adminTag, list)}
                    />
                  </div>
                </div>
              )}
            </li>
          ))}
        </ul>

        <div class={css.createTagInputWrap}>
          <h4>Add a tag</h4>
          <input
            type="text"
            class="input-block"
            placeholder="location:..."
            onKeyDown={(e) => this.handleCreateTagInputKeyDown(e)}
            autocorrect="off"
            autocapitalize="none"
            ref={el => { this.createTagInputRef = el }}
          />
          {state.createSuccess && (
            <p className="form-success-message">{state.createSuccess}</p>
          )}
          {state.createError && (
            <p className="form-error-message">{state.createError}</p>
          )}
        </div>
      </div>
    )
  }

}

export default AdminTagsEditor
