import { h, Component, Fragment } from "preact"
import api from "../utilities/api"
import requireProps from "../utilities/requireProps"
import cx from "classnames"


class ProductDeleteButton extends Component {

  constructor(props) {
    super()

    requireProps(props, [
      'product',
      'apiEndpoint'
    ])

    this.state = {
      product: props.product,
      deleteing: false,
      deleteError: null,
      deleteSuccess: null
    }

    this._clearMsgTimeout = null
  }

  toggleDelete() {
    if (!confirm(`Are you sure you want to delete '${this.state.product.name}'?`)) {
      return
    }
    this.setState({
      deleteError: null,
      deleteSuccess: null
    })
    if (this._clearMsgTimeout) { clearTimeout(this._clearMsgTimeout) }
    api
      .delete(`${this.props.apiEndpoint}/products/${this.state.product.id}`)
      .then(res => {
        this.setState({ deleteing: false })
        if (res && res.errors) {
          return this.setState({ deleteError: res.errors[0] })
        }
        if (res.status_code !== 200) {
          return this.setState({ deleteError: `Product didn't delete: Status ${res.status_code}` })
        }
        // Success
        this.setState({
          product: res.pkg,
          deleteSuccess: `Product deleted successfully, redirecting`,
        })
        window.location.href = '/admin/products/edit'

        // Clear messages after awhile
        this._clearMsgTimeout = setTimeout(() => {
          if (!this._mounted) { return }
          this.setState({ deleteError: null, deleteSuccess: null })
        }, 4000)
      })
    this.setState({ deleteing: true })
  }

  componentDidMount() {
    this._mounted = true
  }

  componentWillUnmount() {
    this._mounted = false
  }

  render(props, state) {
    return (
      <span onClick={this.toggleDelete.bind(this)}>
        {state.deleteing ? 'Deleting...' : 'Delete'}
        {state.deleteError && (
          <>
            &sdot; {state.deleteError}
          </>
        )}
        {state.deleteSuccess && (
          <>
            &sdot; {state.deleteSuccess}
          </>
        )}
      </span>
    )
  }

}

export default ProductDeleteButton
