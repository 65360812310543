import { h, Component, Fragment } from "preact"
import api from "../utilities/api"
import requireProps from "../utilities/requireProps"

class ProductAdminTodosEditor extends Component {

  constructor(props) {
    super()

    requireProps(props, [
      'product',
      'apiEndpoint',
    ])

    this.state = {
      product: props.product,
      updateError: null,
      updateSuccess: null,
    }
  }

  updateProduct(attrs) {
    this.setState({
      updateError: null,
      updateSuccess: null
    })
    api
      .put(`${this.props.apiEndpoint}/products/${this.state.product.id}`, { product: attrs })
      .then(res => {
        if (res && res.errors) {
          return this.setState({ updateError: res.errors[0] })
        }
        if (res.status_code !== 200) {
          return this.setState({ updateError: `Product didn't save: Status ${res.status_code}` })
        }
        // Success
        this.setState({ updateSuccess: `Product successfully updated` })
      })
  }

  handleInputChange(e) {
    if (e.keyCode === 13) {
      // Enter: save new todo item
      e.preventDefault()

      let tmpProduct = { ...this.state.product }

      // Ensure updated products key exists
      if (!tmpProduct.localAdminTodos) {
        tmpProduct.localAdminTodos = [...tmpProduct.adminTodos]
      }

      // Add new todo to both local and database todo lists
      tmpProduct.localAdminTodos.push(e.target.value)
      tmpProduct.adminTodos.push(e.target.value)

      // Update product in state
      this.setState({ product: tmpProduct })

      // Update the database to match the tmpProduct
      // but... we don't sync product.adminTodos with the
      // database, as we want to keep soft deletes of todo items
      this.updateProduct({ adminTodos: JSON.stringify(tmpProduct.localAdminTodos) }, false)

      // Reset input value
      e.target.value = ''
    }
  }

  handleTodoCheck(e) {
    const checkedIndex = Number(e.target.name)
    const checkedTodo = this.state.product.adminTodos.find((todo, i) => i === checkedIndex)
    if (!checkedTodo) { throw new Error(`Todo item not found at index: ${checkedIndex}`) }

    let tmpProduct = { ...this.state.product }

    // Ensure updated products key exists
    if (!tmpProduct.localAdminTodos) {
      tmpProduct.localAdminTodos = [...tmpProduct.adminTodos]
    }

    if (e.target.checked) {
      // Checked, delete item
      tmpProduct.localAdminTodos = tmpProduct.localAdminTodos.filter((todo, i) => {
        return todo !== checkedTodo
      })
    } else {
      // Unchecked, add item back
      tmpProduct.localAdminTodos.push(checkedTodo)
    }

    // Update product in state
    this.setState({ product: tmpProduct })

    // Update the database to match the tmpProduct
    // but... we don't sync product.adminTodos with the
    // database, as we want to keep soft deletes of todo items
    this.updateProduct({ adminTodos: JSON.stringify(tmpProduct.localAdminTodos) }, false)
  }

  render() {

    let adminTodosList = ""
    if (this.state.product.adminTodos && this.state.product.adminTodos.length) {
      adminTodosList = (
        <ul>
          {this.state.product.adminTodos.map((todo, index) => (
            <li>
              <label>
                <input
                  type="checkbox"
                  value="1"
                  name={index}
                  defaultChecked={this.state.product.localAdminTodos && !this.state.product.localAdminTodos.includes(todo)}
                  onChange={(e) => this.handleTodoCheck(e)}
                />
                <span>{todo}</span>
              </label>
            </li>
          ))}
        </ul>
      )
    }

    return (
      <div class={`product-admin-todos-editor ${this.props.className}`}>
        {adminTodosList}
        <input
          type="text"
          id={this.props.id}
          namne="new_todo"
          placeholder="New todo"
          onKeyDown={this.handleInputChange.bind(this)}
        />
      </div>
    )
  }

}

export default ProductAdminTodosEditor
