import { h, Component, Fragment, createRef } from "preact"
import PropTypes from "prop-types"
import css from './ListInput.module.scss'
import cx from 'classnames'

const INPUT_TYPES = ['text', 'textarea']
const TextInput = (props) => <input type="text" {...props} ref={props.inputRef || null} />
const TextareaInput = (props) => <textarea {...props} ref={props.inputRef || null} />

class ListInput extends Component {

  constructor(props) {
    super()

    if (!typeof props.onItemAdd === 'function') {
      throw new Error(`Prop 'onItemAdd' must be a function, got ${typeof props.onItemAdd}`)
    }
    if (!typeof props.onItemUpdate === 'function') {
      throw new Error(`Prop 'onItemUpdate' must be a function, got ${typeof props.onItemUpdate}`)
    }
    if (!typeof props.onItemRemove === 'function') {
      throw new Error(`Prop 'onItemRemove' must be a function, got ${typeof props.onItemRemove}`)
    }

    if (!INPUT_TYPES.includes(props.inputType)) {
      throw new Error(`prop 'inputType' must be one of ${INPUT_TYPES.toString()}`)
    }

    switch (props.inputType) {
      case 'textarea':
        this.isTextarea = true
        this.InputClass = TextareaInput
        break;
        default:
        this.isTextarea = false
        this.InputClass = TextInput
        break;
    }

    this.state = {
      items: props.defaultValues || (props.isKeyValueList ? {} : []),
      error: null,
      editingItemOrKey: null,
      tmpInputValue: null,
    }

    this.editingInputRef = createRef()
    this.createInputRef = createRef()
  }

  handleInputSubmit(inputValue, inputElement=null) {
    this.setState({ error: null, tmpInputValue: inputValue })

    if (!inputValue) {
      return this.setState({
        error: "Can't update value to be empty, try deleting instead",
        tmpInputValue: ''
      })
    }

    let updatedItems

    // Key/Value list
    if (this.props.isKeyValueList) {
      if (inputValue.indexOf(':') <= 0) {
        return this.setState({
          error: "Missing ':' for key/value pair"
        })
      }
      let key = inputValue.split(':')[0]
      let value = inputValue.split(/:/).slice(1).join(':')
      updatedItems = { ...this.state.items }
      const newKey = key.trim()
      if (this.state.editingItemOrKey) {
        if (newKey !== this.state.editingItemOrKey) {
          // Delete old key if no longer relevant
          delete updatedItems[this.state.editingItemOrKey]
        }
      }
      updatedItems[newKey] = value.trim()
      if (this.state.editingItemOrKey) {
        this.props.onItemUpdate(newKey, updatedItems)
      } else {
        this.props.onItemAdd(newKey, updatedItems)
      }
      this.setState({
        items: updatedItems,
        editingItemOrKey: null,
        tmpInputValue: null
      })
    }
    // Standard List/Array
    else {
      updatedItems = [...this.state.items]
      if (updatedItems.indexOf(inputValue) > -1) {
        return this.setState({
          error: "Duplicate item detected"
        })
      }
      if (this.state.editingItemOrKey) {
        // Edit existing item
        const index = updatedItems.indexOf(this.state.editingItemOrKey)
        updatedItems[index] = inputValue
      } else {
        // Append new item
        updatedItems.push(inputValue)
      }
      if (this.state.editingItemOrKey) {
        this.props.onItemUpdate(inputValue, updatedItems)
      } else {
        this.props.onItemAdd(inputValue, updatedItems)
      }
    }

    this.setState({
      items: updatedItems,
      editingItemOrKey: null,
      tmpInputValue: null
    })

    if (inputElement) {
      inputElement.value = ''
    }
  }

  handleInputKeyDown(e) {
    const isEnterKey = e.key === 'Enter'
    const isTextInputSubmit = !this.isTextarea && isEnterKey
    const isTextareaSubmit = this.isTextarea && isEnterKey && e.metaKey

    if (isTextInputSubmit || isTextareaSubmit) {
      e.preventDefault()
      this.handleInputSubmit(e.target.value, e.target)
    }
  }

  handleItemEdit(itemOrKey) {
    this.setState({ editingItemOrKey: itemOrKey }, (state) => {
      if (this._mounted && this.editingInputRef) {
        this.editingInputRef.current.focus()
      }
    })
  }

  handleItemRemove(itemOrKey) {
    if (this.props.isKeyValueList) {
      let newList = { ...this.state.items }
      delete newList[itemOrKey]
      this.props.onItemRemove(itemOrKey, newList)
      return this.setState({ items: newList })
    }
    let index = this.state.items.indexOf(itemOrKey)
    if (index < 0) {
      throw new Error(`Index doesn't exist for item ${itemOrKey} in array ${this.state.items}`)
    }
    let newValues = [...this.state.items]
    newValues.splice(index, 1)
    this.props.onItemRemove(itemOrKey, newValues)
    this.setState({ items: newValues })
  }

  getMappableList() {
    if (this.props.isKeyValueList) {
      return Object.keys(this.state.items)
    }
    return this.state.items
  }

  componentDidMount() {
    this._mounted = true
  }

  componentWillUnmount() {
    this._mounted = false
  }

  render(props, state) {
    let mappableItemsList = this.getMappableList()
    let list
    let getEditingInput = (value) => (
      <>
        <this.InputClass
          id={props.id}
          class={cx(css.input, props.inputClass)}
          value={this.state.tmpInputValue || value}
          placeholder={props.placeholder}
          onKeyDown={this.handleInputKeyDown.bind(this)}
          inputRef={this.editingInputRef}
        />
        <div class={cx(css.submitBtns)}>
          <button
            type="button"
            class="btn btn-submit"
            onClick={() => this.handleInputSubmit(this.editingInputRef.current.value)}
          >Save</button>
          <button
            type="button"
            class="btn btn-cancel"
            onClick={() => this.setState({ error: null, editingItemOrKey: null, tmpInputValue: null })}
          >Cancel</button>
        </div>
      </>
    )

    if (props.isKeyValueList) {
      list = (
        <table class={css.listInputTable}>
          {mappableItemsList.map(key => {
            return (
              <tr>
                {state.editingItemOrKey === key ? (
                  <td colspan="2">
                    {getEditingInput(`${key}:${this.state.items[key]}`)}
                  </td>
                ) : (
                  <>
                    <th>{key}</th>
                    <td>
                      <span dangerouslySetInnerHTML={{
                        __html: state.items[key].replaceAll('\n', '<br />')
                      }}></span>
                      <i
                        onClick={() => this.handleItemEdit(key)}
                        class={cx('icon-edit link', css.editLink)}
                      ></i>
                      <i
                        onClick={() => this.handleItemRemove(key)}
                        class={cx('icon-close1 link', css.deleteLink)}
                      ></i>
                    </td>
                  </>
                )}
              </tr>
            )
          })}
        </table>
      )
    } else {
      list = (
        <ul class={css.listInputList}>
          {mappableItemsList.map(item => {
            return (
              <li>
                {state.editingItemOrKey === item ? getEditingInput(item) : (
                  <>
                    <span dangerouslySetInnerHTML={{
                      __html: item.replaceAll('\n', '<br />')
                    }}></span>
                    <i
                      onClick={() => this.handleItemEdit(item)}
                      class={cx('icon-edit link', css.editLink)}
                    ></i>
                    <i
                      onClick={() => this.handleItemRemove(item)}
                      class={cx('icon-close1 link', css.deleteLink)}
                    ></i>
                  </>
                )}
              </li>
            )
          })}
        </ul>
      )
    }
    return (
      <div class={css.listInput}>
        {mappableItemsList.length > 0 && list}
        <input type="hidden" name={props.name} value={JSON.stringify(state.items)} />
        {state.error && (
          <p class="form-error-message" style={{ marginBottom: "10px" }}>{state.error}</p>
        )}
        {!state.editingItemOrKey && (
          <>
            <this.InputClass
              id={props.id}
              class={cx(css.input, props.inputClass)}
              placeholder={props.placeholder}
              onKeyDown={this.handleInputKeyDown.bind(this)}
              inputRef={this.createInputRef}
            />
            <div class={cx(css.submitBtns)}>
              <button
                type="button"
                class="btn btn-submit"
                onClick={() => {
                  this.handleInputSubmit(this.createInputRef.current.value, this.createInputRef.current)
                }}
              >Save</button>
            </div>
          </>
        )}
      </div>
    )
  }

}

ListInput.propTypes = {
  onItemAdd: PropTypes.func,
  onItemUpdate: PropTypes.func,
  onItemRemove: PropTypes.func,
  isKeyValueList: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  inputType: PropTypes.string,
  defaultValues: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

ListInput.defaultProps = {
  onItemAdd: (item, list) => {},
  onItemUpdate: (item, list) => {},
  onItemRemove: (item, list) => {},
  isKeyValueList: false,
  inputType: 'text',
  id: '',
  placeholder: '',
}

export default ListInput
