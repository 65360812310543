
function getImageDimensionsFromFile(file, timeout=3000) {
  return new Promise((resolve, reject) => {

    // For whatever reason this might fail, set
    // a timeout limit of 3 (default) seconds
    let timeElapsed = 0
    const timeoutInterval = setInterval(() => {
      timeElapsed += 100
      if (timeElapsed > timeout) {
        clearInterval(timeoutInterval)
        reject("Timeout exceeded")
      }
    }, 100);

    if (typeof FileReader !== 'function') {
      reject("FileReader not supported in browser")
    }

    // Load the image into an Image to check size
    var fr = new FileReader()
    fr.onload = function () { // file is loaded
      var img = new Image()
      img.onload = function () {
        clearTimeout(timeoutInterval)
        // image is loaded; sizes are available
        resolve({
          width: img.width,
          height: img.height
        })
      }
      img.src = fr.result // is the data URL because called with readAsDataURL
    }
    fr.readAsDataURL(file)
  })
}
export default getImageDimensionsFromFile
