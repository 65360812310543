import { Component, h } from "preact"
import PropTypes from 'prop-types'
import requireProps from "../utilities/requireProps"
import ProductImageThumbnails from "./ProductImageThumbnails"
import api from "../utilities/api"


class CategoryImageChooser extends Component {

  constructor(props) {
    super()

    requireProps(props, [
      'category',
      'productPhotos'
    ])

    let activeImageId = null
    props.productPhotos.forEach(photo => {
      if (photo.image_url_600 === props.category.image_url_600) {
        activeImageId = photo.id
      }
    })

    this.state = {
      activeImageId,
      categorySaveSuccess: null,
      categorySaveError: null
    }
  }

  getActiveImage() {
    return this.props.productPhotos.find(photo => photo.id === this.state.activeImageId)
  }

  handleThumbnailClick(productPhoto) {
    this.setState({
      categorySaveSuccess: null,
      categorySaveError: null,
    })
    api
    .put(`${this.props.apiEndpoint}/categories/${this.props.category.id}`, {
      category: {
        image_url_150: productPhotoImageUrl150,
        image_url_300: productPhotoImageUrl300,
        image_url_600: productPhotoImageUrl600,
        image_url_900: productPhotoImageUrl900,
        image_url_1200: productPhotoImageUrl1200,
        image_url_1500: productPhotoImageUrl1500,
        image_url_1800: productPhotoImageUrl1800,
      }
    })
    .then(res => {
      if (res && res.errors) {
        return this.setState({ savePhotoError: res.errors[0] })
      }
      if (res.status_code !== 200) {
        return this.setState({ savePhotoError: `Category didn't save: Status ${res.status_code}` })
      }
      // Success
      this.setState({
        category: res.pkg,
        categorySaveSuccess: "Image updated",
        categorySaveError: null,
        activeImageId: productPhoto.id
      })
    })
  }

  render() {
    return (
      <div>
        {this.state.categorySaveSuccess && (
          <p class="form-success-message">{this.state.categorySaveSuccess}</p>
        )}
        {this.state.categorySaveError && (
          <p class="form-error-message">{this.state.categorySaveError}</p>
        )}
        {this.props.productPhotos.length > 0 && (
          <ProductImageThumbnails
            product={this.props.product}
            productPhotos={this.props.productPhotos}
            highlightedImageId={this.state.activeImageId}
            onThumbnailClick={this.handleThumbnailClick.bind(this)}
          />
        )}
      </div>
    )
  }
}

CategoryImageChooser.defaultProps = {}

CategoryImageChooser.propTypes = {
  category: PropTypes.object.isRequired,
  productPhotos: PropTypes.array.isRequired,
}

export default CategoryImageChooser
