import getNthDay from "./getNthDay"

function throwDateError(varName) {
  throw new Error(`'${varName}' must be an instance of a Date object`)
}

export default function getHireDateStrings(hireDate, returnDate) {
  if (typeof hireDate.getMonth !== 'function') { throwDateError(hireDate) }
  if (typeof returnDate.getMonth !== 'function') { throwDateError(returnDate) }

  const today = new Date()
  const nthHireDay = getNthDay(hireDate.getDate())
  const nthReturnDay = getNthDay(returnDate.getDate())
  const hireMonth = hireDate.toLocaleString('default', { month: 'long' })
  const returnMonth = returnDate.toLocaleString('default', { month: 'long' })
  const isHireDateToday = today.toDateString() === hireDate.toDateString()
  const isHireDateTomorrow = today.addDays(1).toDateString() === hireDate.toDateString()
  const isReturnDateToday = today.toDateString() === returnDate.toDateString()
  const isReturnDateTomorrow = today.addDays(1).toDateString() === returnDate.toDateString()
  const hireYear = hireDate.getFullYear()
  const returnYear = returnDate.getFullYear()
  const isHiringWithinThisYear = today.getFullYear() === hireYear && today.getFullYear() === returnYear

  const hireDateString = `${nthHireDay} of ${hireMonth}${isHiringWithinThisYear ? '' : ` ${hireYear}`}`
  const returnDateString = `${nthReturnDay} of ${returnMonth}${isHiringWithinThisYear ? '' : ` ${returnYear}`}`

  const dateStrings = {
    hireDate,
    returnDate,
    isHireDateToday,
    isHireDateTomorrow,
    isReturnDateToday,
    isReturnDateTomorrow,
    hireDateString,
    returnDateString,
  }

  return dateStrings
}
