import { h, Component } from "preact"
import requireProps from "../utilities/requireProps"
import api from "../utilities/api"
import cx from "classnames"

function naiveRound(num, decimalPlaces = 2) {
  var p = Math.pow(10, decimalPlaces)
  return Math.round(num * p) / p
}

class ProductsSearch extends Component {

  constructor(props) {
    super()

    requireProps(props, [
      'products',
      'apiEndpoint',
      'RATES_COLUMNS'
    ])

    this.state = {
      filter: '',
      highlightedIndex: -1,
      products: props.products // comes from Rails, so we can change this now
    }

    this.listRef = null
  }

  scrollItemAtIndexIntoView(index) {
    if (!this.listRef) { return }

    const highlightedItem = this.listRef.querySelector("li.highlighted")

    if (highlightedItem) {
      highlightedItem.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest'
      })
    }
  }

  handleKeyUp(e) {
    const { keyCode } = e
    const filteredProducts = this.getFilteredProducts()
    const { highlightedIndex } = this.state

    if (keyCode === 40 && highlightedIndex < filteredProducts.length - 1) {
      // Down Arrow
      e.preventDefault()
      const nextIndex = highlightedIndex + 1

      if (nextIndex < filteredProducts.length) {
        this.setState({ highlightedIndex: nextIndex })
        window.setTimeout(() => this.scrollItemAtIndexIntoView(nextIndex), 10)
      }
    }
    else if (keyCode === 38 && highlightedIndex > 0) {
      // Up Arrow
      e.preventDefault()
      const nextIndex = highlightedIndex - 1

      if (nextIndex >= 0) {
        this.setState({ highlightedIndex: nextIndex })
        window.setTimeout(() => this.scrollItemAtIndexIntoView(nextIndex), 10)
      }
    }
  }

  handleKeyPress(e) {
    if (e.keyCode === 13) {
      // Route to highlighted product on Enter press (if one exists)
      if (highlightedIndex === -1) { return }
      const { highlightedIndex } = this.state
      const filteredProducts = this.getFilteredProducts()
      const highlightedProduct = filteredProducts.find((p, index) => index === highlightedIndex)
      window.location.href = `/products/${highlightedProduct.slug}`
    }
  }

  handleFilterChange(e) {
    this.setState({ filter: e.target.value })
  }

  handleSearchClear() {
    this.setState({ filter: '' })
  }

  getFilteredProducts() {
    const { filter, products } = this.state
    const filteredProducts = products.filter((p) => {
      return (
        filter &&
        (p.name + (p.description || ""))
          .toLowerCase()
          .includes(filter.toLowerCase())
      )
    })

    return filteredProducts
  }

  render(props, state) {
    const filteredProducts = this.getFilteredProducts()

    if (!state.products.length) {
      return (
        <p>No products</p>
      )
    }

    return (
      <div class="ProductsSearch">
        <div class="admin-browse-header-actions">
          <div class="ProductsSearch__input-wrap">
            <input
              type="text"
              placeholder="Product search..."
              onInput={this.handleFilterChange.bind(this)}
              onKeyDown={this.handleKeyUp.bind(this)}
              onKeyPress={this.handleKeyPress.bind(this)}
              value={this.state.filter}
            />
            <i class="icon-close1" onClick={this.handleSearchClear.bind(this)}></i>
          </div>
        </div>

        <ul
          class="admin-browse-list ProductsSearch-products-list"
          ref={(el) => (this.listRef = el)}
        >
          {filteredProducts.map((product, index) => {
            const isHighlighted = index === state.highlightedIndex
            const classes = cx({
              "admin-browse-list__item": true,
              "highlighted": isHighlighted,
            })
            return (
              <li class={classes}>
                <a href={`/products/${product.slug}`}>
                  <div class="admin-browse-list__img-wrap">
                    {product.featureImageUrl150 ? (
                      <img src={product.featureImageUrl150} />
                    ) : (
                      <i class="icon-images text-grey"></i>
                    )}
                  </div>
                  <span>{product.name}</span>
                  <ul class="admin-browse-item__info">
                    {product.categories && product.categories.length > 0 && (
                      <li>{product.categories[0].name}</li>
                    )}
                    {props.RATES_COLUMNS.map((rate) => {
                      if (!product[rate.name]) { return '' }
                      return (
                        <li class="info-pricing">
                          ${naiveRound(product[rate.name])}/{rate.abrv}
                        </li>
                      )
                    })}
                  </ul>
                </a>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

}

export default ProductsSearch
