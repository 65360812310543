import { h, Component, Fragment } from "preact"
import requireProps from "../utilities/requireProps"
import api from "../utilities/api"
import cx from "classnames"
import Dialog from "./Dialog"
import TagsInput from "./TagsInput"
import throttle from "lodash.throttle"
import ProductListMinimal from "./ProductListMinimal"
import AdminTagsPickerList from "./AdminTagsPickerList"
import css from "./AdminProductTagsEditor.module.scss"

const SORT_DEFAULT  = 'default'
const SORT_NAME     = 'name'
const SORT_TAGS     = 'tags'
const SORT_CATEGORY = 'category'
const SORT_CREATED  = 'created'
const SORT_RECENT   = 'recent'

class AdminProductTagsEditor extends Component {

  constructor(props) {
    super()

    requireProps(props, [
      'products',
      'adminTags',
      'apiEndpoint'
    ])

    api.setEndpoint(props.apiEndpoint)

    this.state = {
      products: props.products,
      adminTags: props.adminTags,
      loading: false,
      dialogError: null,
      dialogSuccess: null,
      sortBy: SORT_DEFAULT,
      sortReverse: false,
      filterByTags: [],
      selectedProductsLookup: {},
      shouldDeleteLast: false,
      dialogIsOpen: false,
      dialogHasChanges: false,
    }

    this._checkBottomBarStickiness = throttle(this.checkBottomBarStickiness, 150).bind(this)
  }

  onDialogDone() {
    const stateChanges = {
      dialogIsOpen: false,
      dialogHasChanges: false,
    }

    if (this.state.dialogHasChanges) {
      // Unselect all products
      stateChanges.selectedProductsLookup = {}
    }

    this.setState(stateChanges)
  }

  handleAddTagToFilter(tagName) {
    if (this.state.filterByTags.includes(tagName)) {
      return // tag already selected
    }
    // Validate it exists
    const adminTag = this.state.adminTags.find(at => at.name === tagName)
    if (!adminTag) { throw new Error(`AdminTag:${tagName} doesn't exist in list`) }
    // Add it
    this.setState({ filterByTags: [...this.state.filterByTags, tagName] })
  }

  handleRemoveTagToFilter(tagName) {
    if (!this.state.filterByTags.includes(tagName)) {
      throw new Error(`Tag '${tagName}' is not selected`)
    }
    // Tag exists, remove it
    this.setState({ filterByTags: this.state.filterByTags.filter(tn => tn !== tagName) })
  }

  handleShouldDeleteLastChange(e) {
    const { checked } = e.target
    this.setState({ shouldDeleteLast: checked })
  }

  handleProductSelectedClick(e) {
    const productId = Number(e.target.getAttribute('data-product-id'))
    const updatedSelectedProductsLookup = {
      ...this.state.selectedProductsLookup,
      [productId]: !this.state.selectedProductsLookup[productId]
    }
    this.setState({
      selectedProductsLookup: updatedSelectedProductsLookup
    })
  }

  handleSortByClick(sortBy) {
    this.setState({
      sortBy,
      sortReverse: !this.state.sortReverse && this.state.sortBy === sortBy
    })
  }

  getFilteredProducts() {
    if (this.state.filterByTags.length === 0) {
      return [ ...this.state.products ]
    }
    return this.state.products.filter(product => {
      return product.adminTags.find(at => this.state.filterByTags.includes(at.name))
    })
  }

  getVisibleSelectedProducts() {
    const visibleSelectedProducts = []
    this.getFilteredProducts().forEach(product => {
      if (this.state.selectedProductsLookup[product.id]) {
        visibleSelectedProducts.push(product)
      }
    })
    return visibleSelectedProducts
  }

  getVisibleSelectedProductsAdminTags() {
    const sharedAdminTags = []
    this.getVisibleSelectedProducts().forEach(p => {
      p.adminTags.forEach(adminTag => {
        const existingAdminTag = sharedAdminTags.find(at => at.id === adminTag.id)
        if (!existingAdminTag) {
          sharedAdminTags.push({ ...adminTag, count: 1 })
        } else {
          existingAdminTag.count += 1
        }
      })
    })
    return sharedAdminTags
  }

  async handleTagAdd(newOrExistingTag, data) {
    const {
      visibleSelectedProducts,
      visibleSelectedProductsAdminTags
    } = data

    const updatedAdminTags = [...this.state.adminTags ]
    let adminTag = newOrExistingTag

    // console.log('handleTagAdd newOrExistingTag', newOrExistingTag)
    this.setState({ dialogError: null, loading: true, dialogHasChanges: true })

    if (!adminTag.id) {
      // Create AdminTag first
      try {
        const createResponse = await api.post(`admin_tags`, {
          admin_tag: { name: adminTag.name }
        })
        // console.log('AdminTags created', createResponse)
        updatedAdminTags.push(createResponse.pkg)
        // console.log("AdminTag pushed to updatedAdminTags", createResponse.pkg)
        if (createResponse.errors) {
          return this.setState({ dialogError: createResponse.errors[0], loading: false })
        }
        adminTag = createResponse.pkg
      } catch (error) {
        console.error(`Creating AdminTag failed: ${error}`);
        return this.setState({ dialogError: error.toString(), loading: false })
      }
    }

    // Find all products that don't already include adminTag
    // We will be sending this array in the api request
    const productIdsToAssign = []
    const productIdsToAssignLookup = {}
    visibleSelectedProducts.forEach(product => {
      if (!product.adminTags.find(at => at.id === adminTag.id)) {
        productIdsToAssign.push(product.id)
        productIdsToAssignLookup[product.id] = true
      }
    })

    // Make the API call to assign AdminTag to selected Products
    // console.log('Making the API call to assign AdminTag to selected Products. productIdsToAssign:', productIdsToAssign)
    let assignResponse = null
    try {
      console.log("productIdsToAssign", productIdsToAssign)
      assignResponse = await api.post(`admin_tags/${adminTag.id}/assign_products`, {
        productIds: productIdsToAssign
      })
      // console.log('assignResponse', assignResponse)
      if (assignResponse.errors) {
        // console.log('assignResponse.errors', assignResponse.errors)
        return this.setState({ dialogError: assignResponse.errors[0], loading: false })
      }
    } catch (error) {
      console.error(`Assigning Products to AdminTag:${adminTag.id} failed: ${error}`)
      return this.setState({ dialogError: error.toString(), loading: false })
    }

    // Update products to include adminTag in their adminTags array, and
    // the newly created productAdminTag in their productAdminTags array.
    const updatedProducts = [...this.state.products]
    updatedProducts.forEach((product, index) => {
      if (productIdsToAssignLookup[product.id]) {
        updatedProducts[index] = { ...product }

        // add adminTag to product locally (if it doesn't exist)
        const existingAdminTag = updatedProducts[index].adminTags.find(at => at.id === adminTag.id)
        if (!existingAdminTag) {
          updatedProducts[index].adminTags = [...product.adminTags, adminTag]
        }

        // add productAdminTag to product locally
        const newProductAdminTag = assignResponse.pkg.productAdminTags.find(apt => apt.productId === product.id)
        if (!newProductAdminTag) {
          throw new Error(`Server didn't return a productAdminTag for product:${product.id}`)
        }
        updatedProducts[index].productAdminTags = [...product.productAdminTags,  newProductAdminTag]
      }
    })

    this.setState({
      loading: false,
      dialogSuccess: 'Successfully assigned tag to products',
      products: updatedProducts,
      adminTags: updatedAdminTags
    })

    // Clear messages after some time
    this._clearMsgTimeout = setTimeout(() => {
      if (!this._mounted) { return }
      this.setState({ dialogError: null, dialogSuccess: null })
    }, 4000)
  }

  async handleTagRemove(newOrExistingTag, data) {
    const {
      visibleSelectedProducts,
      visibleSelectedProductsAdminTags
    } = data

    let updatedAdminTags = [...this.state.adminTags]
    let adminTag = newOrExistingTag

    // console.log('handleTagRemove newOrExistingTag', newOrExistingTag)
    this.setState({ dialogError: null, loading: true, dialogHasChanges: true })

    if (!adminTag.id) {
      // Unlikely to happen, but ignore unsaved tags
      return this.setState({
        dialogError: `AdminTag not saved to DB. Skipping removal.`,
        loading: false
      })
    }

    // Find all products that have this adminTag
    // We will be sending this array in the api request
    const productIdsToUnassign = []
    const productIdsToUnassignLookup = {}
    visibleSelectedProducts.forEach(product => {
      if (product.adminTags.find(at => at.id === adminTag.id)) {
        productIdsToUnassign.push(product.id)
        productIdsToUnassignLookup[product.id] = true
      }
    })

    // Make the API call to unassign AdminTag to selected Products
    // console.log('Making the API call to unassign AdminTag from selected Products. productIdsToUnassign:', productIdsToUnassign)
    let unassignResponse = null
    try {
      unassignResponse = await api.post(`admin_tags/${adminTag.id}/unassign_products`, {
        productIds: productIdsToUnassign,
        deleteLast: this.state.shouldDeleteLast
      })
      // console.log('unassignResponse', unassignResponse)
      if (unassignResponse.errors) {
        // console.log('unassignResponse.errors', unassignResponse.errors)
        return this.setState({ dialogError: unassignResponse.errors[0], loading: false })
      }
      if (unassignResponse.pkg.deletedLast) {
        updatedAdminTags = updatedAdminTags.filter(at => at.id !== adminTag.id)
      }
    } catch (error) {
      console.error(`Assigning Products to AdminTag:${adminTag.id} failed: ${error}`)
      return this.setState({ dialogError: error.toString(), loading: false })
    }

    // Update products to remove adminTag & productAdminTag from their respective arrays
    const updatedProducts = [...this.state.products]
    updatedProducts.forEach((product, index) => {
      if (productIdsToUnassignLookup[product.id]) {
        updatedProducts[index] = { ...product }

        // remove adminTag from product locally (if it exists)
        const existingAdminTag = updatedProducts[index].adminTags.find(at => at.id === adminTag.id)
        if (!existingAdminTag) {
          throw new Error(`AdminTag '${adminTag.name}' doesn't exist on product:${product.id}, so can't remove`)
        }
        updatedProducts[index].adminTags = updatedProducts[index].adminTags.filter(at => {
          return at.id !== adminTag.id
        })

        // remove productAdminTag from product locally (if it exists)
        const existingProductAdminTag = updatedProducts[index].productAdminTags.find(pat => {
          return pat.productId === product.id && pat.adminTagId === adminTag.id
        })
        if (!existingProductAdminTag) {
          throw new Error(`No productAdminTag for tag '${adminTag.name}' exists on product:${product.id}, so can't remove`)
        }
        updatedProducts[index].productAdminTags = updatedProducts[index].productAdminTags.filter(pat => {
          return pat.id !== existingProductAdminTag.id
        })
      }
    })

    this.setState({
      loading: false,
      dialogSuccess: 'Successfully unassigned tag to products',
      products: updatedProducts,
      adminTags: updatedAdminTags
    })

    // Clear messages after some time
    this._clearMsgTimeout = setTimeout(() => {
      if (!this._mounted) { return }
      this.setState({ dialogError: null, dialogSuccess: null })
    }, 4000)
  }

  async handleAdminTagPickerClick(adminTag, data) {
    const {
      visibleSelectedProducts,
      visibleSelectedProductsAdminTags
    } = data

    // console.log('handleAdminTagPickerClick adminTag', adminTag)

    // Check if all products have been assigned this adminTag
    let allProductsHaveAdminTag = true
    visibleSelectedProducts.forEach(p => {
      const foundTag = p.adminTags.find(at => at.id === adminTag.id)
      if (!foundTag) { allProductsHaveAdminTag = false }
    })
    // If all products are assigned this tag, then we can do nothing
    if (allProductsHaveAdminTag) {
      // console.log(`skipping`)
      return
    }

    // Trigger a loading state (spinning css transition icon) on the tag that was clicked
    let updatedAdminTags = this.state.adminTags.map(at => {
      if (at.id === adminTag.id) {
        return { ...at, loading: true }
      }
      return { ...at }
    })
    this.setState({ adminTags: updatedAdminTags })

    // Trigger tag add
    await this.handleTagAdd(adminTag, data)

    // Turn off loading state
    updatedAdminTags = updatedAdminTags.map(at => {
      if (at.id === adminTag.id) {
        return { ...at, loading: false }
      }
      return { ...at }
    })
    this.setState({ adminTags: updatedAdminTags })
  }

  adminTagClassSetter(adminTag, data) {
    const {
      visibleSelectedProducts,
      visibleSelectedProductsAdminTags
    } = data
    const foundTag = visibleSelectedProductsAdminTags.find(at => {
      return at.name === adminTag.name // use name as tag might not be saved to DB yet
    })
    let className = adminTag.loading ? css.loading : ''

    // Case 1: Tag not in selected products
    if (!foundTag) { return cx(className, css.tagNotInProducts) }

    // Case 2: Tag in ALL of the selected products
    if (foundTag.count === visibleSelectedProducts.length) {
      return cx(className, css.tagInAllProducts)
    }

    // Case 2: Tag in SOME of the selected products
    return cx(className, css.tagInSomeProducts)
  }

  checkBottomBarStickiness() {
    const scrollOffset = -42

    function getRectTop(el) {
      var rect = el.getBoundingClientRect()
      return rect.top
    }

    const footer = document.getElementById('twizelhire-footer')
    const bottomPanel = document.getElementById('twizelhire-tags-editor-opener-bottom-panel')

    const scrollTop         = document.body.scrollTop
    const footerOsset       = getRectTop(footer) + scrollTop + scrollOffset
    const bottomPanelOffset = getRectTop(bottomPanel) + scrollTop + bottomPanel.offsetHeight

    if (bottomPanelOffset >= footerOsset) {
      bottomPanel.classList.add(css.sticky)
    }
    if (scrollTop + window.innerHeight < footerOsset) {
      bottomPanel.classList.remove(css.sticky)
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this._checkBottomBarStickiness)
    this._mounted = true
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this._checkBottomBarStickiness)
    this._mounted = false
  }

  render(props, state) {

    const adminTagsLookup = {}
    state.adminTags.forEach(at => {
      adminTagsLookup[at.id] = at
    })

    if (!state.products.length) {
      return <p>No products</p>
    }

    let processedProducts = this.getFilteredProducts()

    // If sortBy is set
    if (state.sortBy !== SORT_DEFAULT) {
      processedProducts = processedProducts.sort((productA, productB) => {
        switch (state.sortBy) {
          case SORT_CATEGORY:
            if (!productA.categories[0] || !productB.categories[0]) {
              return state.sortReverse ? -1 : 1
            }
            const categoryNameA = productA.categories[0].name.toUpperCase()
            const categoryNameB = productB.categories[0].name.toUpperCase()
            if (categoryNameA < categoryNameB) { return state.sortReverse ? -1 : 1 }
            if (categoryNameA > categoryNameB) { return state.sortReverse ? 1 : -1 }
          case SORT_TAGS:
            const productATodos = productA.adminTags ? productA.adminTags.length : 0
            const productBTodos = productB.adminTags ? productB.adminTags.length : 0
            return state.sortReverse ? productATodos - productBTodos : productBTodos - productATodos
          case SORT_CREATED:
            return state.sortReverse ? productA.createdAt - productB.createdAt : productB.createdAt - productA.createdAt
          case SORT_RECENT:
            return state.sortReverse ? productA.updatedAt - productB.updatedAt : productB.updatedAt - productA.updatedAt
          default: // SORT_NAME
            const nameA = productA.name.toUpperCase()
            const nameB = productB.name.toUpperCase()
            if (nameA < nameB) { return state.sortReverse ? 1 : -1 }
            if (nameA > nameB) { return state.sortReverse ? -1 : 1 }
        }
      })
    }

    const visibleSelectedProducts = this.getVisibleSelectedProducts()
    const visibleSelectedProductsAdminTags = this.getVisibleSelectedProductsAdminTags()

    return (
      <div class={css.adminProductTagsEditor}>
        <div class={css.header}>
          <ul class={cx({ reverse: state.sortReverse })}>
            <li
              class={cx({ [css.active]: state.sortBy === SORT_DEFAULT }, css.noArrow)}
              onClick={() => this.handleSortByClick(SORT_DEFAULT)}
            >None</li>
            <li
              class={cx({ [css.active]: state.sortBy === SORT_NAME })}
              onClick={() => this.handleSortByClick(SORT_NAME)}
            >Name</li>
            <li
              class={cx({ [css.active]: state.sortBy === SORT_TAGS })}
              onClick={() => this.handleSortByClick(SORT_TAGS)}
            >Tags</li>
            <li
              class={cx({ [css.active]: state.sortBy === SORT_CATEGORY })}
              onClick={() => this.handleSortByClick(SORT_CATEGORY)}
            >Category</li>
            <li
              class={cx({ [css.active]: state.sortBy === SORT_RECENT })}
              onClick={() => this.handleSortByClick(SORT_RECENT)}
            >Recent</li>
            <li
              class={cx({ [css.active]: state.sortBy === SORT_CREATED })}
              onClick={() => this.handleSortByClick(SORT_CREATED)}
            >Created</li>
          </ul>
        </div>

        {state.filterByTags.length > 0 && (
          <div class={css.filteredByTags}>
            <h4>Filtered by:</h4>
            <ul>
              {state.filterByTags.map(tagName => (
                <li>
                  {tagName}
                  <i
                    className="icon-cross"
                    onClick={() => this.handleRemoveTagToFilter(tagName)}
                  ></i>
                </li>
              ))}
            </ul>
          </div>
        )}

        <div class={css.sectionsWrap}>
          <div class={css.productsSection}>

            {processedProducts.length === 0 && <p>No products match those filters</p>}

            <ul>
              {processedProducts.map(product => {
                return (
                  <li class={css.productsSectionListItem}>

                    <div class="flex">
                      {/* Checkbox label */}
                      <label class="label-checkbox">
                        <div className={css.input}>
                          <input
                            type="checkbox"
                            data-product-id={product.id}
                            onChange={this.handleProductSelectedClick.bind(this)}
                            checked={!!state.selectedProductsLookup[product.id]}
                          />
                        </div>
                        <div class={cx('admin-browse-list__img-wrap', css.img)}>
                          {product.featureImageUrl150 ? (
                            <img src={product.featureImageUrl150} />
                          ) : (
                            <i class="icon-images text-grey"></i>
                          )}
                        </div>
                        <div class={css.productNameWrap}>
                          <span class={css.productName}>{product.name}</span>

                          {/* Date Edited (if sorted by RECENT) */}
                          {state.sortBy === SORT_RECENT && (
                            <ul class={css.productsSectionListItemSubList}>
                              <li>{(new Date(product.updatedAt)).toISOString().split('T')[0]}</li>
                            </ul>
                          )}
                          {/* Date Added (if sorted by CREATED) */}
                          {state.sortBy === SORT_CREATED && (
                            <ul class={css.productsSectionListItemSubList}>
                              <li>{(new Date(product.createdAt)).toISOString().split('T')[0]}</li>
                            </ul>
                          )}
                          {/* Categories (if sorted by them) */}
                          {state.sortBy === SORT_CATEGORY && !!product.categories.length && (
                            <ul class={css.productsSectionListItemSubList}>
                              {product.categories.map(category => (
                                <li>{category.name}</li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </label>

                      {/* Link to product */}
                      <div class={css.productLinkWrap}>
                        <a href={`/products/${product.slug}`} class="link-darkgrey">
                          <i class="icon-external-link"></i>
                        </a>
                        &nbsp;
                        <a href={`/products/${product.slug}/edit`} class="link-darkgrey">
                          <i class="icon-edit1"></i>
                        </a>
                      </div>
                    </div>

                    {/* Tags list for product */}
                    {!!product.productAdminTags.length && (
                      <ul class={css.adminTagsList}>
                        {product.productAdminTags.map(pat => (
                          <li onClick={() => this.handleAddTagToFilter(adminTagsLookup[pat.adminTagId].name)}>
                            {adminTagsLookup[pat.adminTagId].name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                )
              })}
            </ul>
          </div>

          <div class={css.tagEditorSection}>
            {/* <ProductAdminTagsInput /> */}
          </div>
        </div>{/* end .admin-product-tags-editor__sections-wrap */}

        <div class={css.bottomPanelWrap}>
          <div
            class={css.bottomPanel}
            id="twizelhire-tags-editor-opener-bottom-panel"
          >
            <button
              type="button"
              class="btn btn-blue btn-large"
              disabled={!visibleSelectedProducts.length}
              onClick={() => this.setState({ dialogIsOpen: true })}
            >
              Assign tags to {visibleSelectedProducts.length} products
            </button>
            <span
              class={cx('link', css.deselect, {
                disabled: !visibleSelectedProducts.length
              })}
              onClick={() => visibleSelectedProducts.length && this.setState({ selectedProductsLookup: {} })}
            >Delselect all</span>
          </div>
        </div>

        <Dialog
          open={state.dialogIsOpen}
          onClose={() => this.setState({ dialogIsOpen: false })}
          class={css.tagEditorDialog}
        >
          <h1>Bulk edit tags</h1>
          <ProductListMinimal products={visibleSelectedProducts} />
          <TagsInput
            onTagAdd={(tag) => this.handleTagAdd(tag, {
              visibleSelectedProducts,
              visibleSelectedProductsAdminTags
            })}
            onTagRemove={(tag) => this.handleTagRemove(tag, {
              visibleSelectedProducts,
              visibleSelectedProductsAdminTags
            })}
            defaultTags={visibleSelectedProductsAdminTags}
            tagClassSetter={(at) => this.adminTagClassSetter(at, {
              visibleSelectedProducts,
              visibleSelectedProductsAdminTags
            })}
            tagNameSetter={(tagName) => {
              const foundAdminTag = state.adminTags.find(at => at.name === tagName)
              if (foundAdminTag) {
                return foundAdminTag
              }
              return { name: tagName }
            }}
            tagNameGetter={(tag) => tag.name}
            placeholder="Enter tag..."
            key={`tags-input-length-${visibleSelectedProductsAdminTags.length}`} // force ui updates when list changes
          />
          <label class={cx('label-checkbox-wrap', css.shouldDeleteLast)}>
            <input
              type="checkbox"
              onChange={this.handleShouldDeleteLastChange.bind(this)}
              defaultChecked={this.state.shouldDeleteLast}
            />
            Delete AdminTag (if last)
          </label>
          <AdminTagsPickerList
            adminTags={state.adminTags}
            onTagClick={(at) => this.handleAdminTagPickerClick(at, {
              visibleSelectedProducts,
              visibleSelectedProductsAdminTags
            })}
            tagClassSetter={(at) => this.adminTagClassSetter(at, {
              visibleSelectedProducts,
              visibleSelectedProductsAdminTags
            })}
          />
          {state.dialogSuccess && (
            <p class="form-success-message">{state.dialogSuccess}</p>
          )}
          {state.dialogError && (
            <p class="form-error-message">{state.dialogError}</p>
          )}
          <div class={css.dialogBtnWrap}>
            <span
              class={cx('btn btn-large btn-fw', {
                'btn-done': state.dialogHasChanges,
                'btn-cancel': !state.dialogHasChanges,
              })}
              onClick={this.onDialogDone.bind(this)}
            >{state.dialogHasChanges ? 'Done' : 'Cancel'}</span>
          </div>
        </Dialog>
      </div>
    )
  }

}

export default AdminProductTagsEditor
