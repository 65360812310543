import { h, Component, Fragment } from "preact"
import cx from "classnames"
import pick from 'lodash.pick'
import CalendarPicker from "./CalendarPicker"
import api from "../utilities/api"
import formDataToObject from "../utilities/formDataToObject"
import calcDaysHire from "../utilities/calcDaysHire"
import getHireDateStrings from "../utilities/getHireDateStrings"

const REQUEST_ITEM_FORM_SAVED_CONTACT = 'requestItemFormSavedContact'

const OPTION_TODAY = 'today'
const OPTION_TOMORROW = 'tomorrow'
const OPTION_CHOOSE = 'choose_date'

class ProductRequestForm extends Component {

  constructor(props) {
    super()

    if (!props.product) {
      throw new Error("'product' is a required prop of ProductRequestForm")
    }

    Date.prototype.addYears = function (n) {
      let date = new Date()
      return new Date(date.setFullYear(date.getFullYear() + n))
    }
    Date.prototype.subtractDays = function (n) {
      let date = new Date()
      let oneDay = 24 * 60 * 60 * 1000
      return new Date(date.getTime() - (oneDay * n))
    }
    Date.prototype.addDays = function (n) {
      let date = new Date()
      let oneDay = 24 * 60 * 60 * 1000
      return new Date(date.getTime() + (oneDay * n))
    }
    let now = new Date()
    this.minHireDate = now.subtractDays(1)
    this.maxHireDate = now.addYears(1)

    this.hireDatePickerInstance = null
    this.returnDatePickerInstance = null

    this.userHasChosenReturnDate = false

    this.state = {
      hireDate: null,
      returnDate: null,
      sendingRequest: false,
      sendErrorMsg: null,
      sendSuccessMsg: null,

      isHalfDayAM: false,
      isHalfDayPM: false,

      // Start date buttons show on load
      showStartDateButtons: true,
    }

    // Request form data
    this.formDataFields = ['email', 'phone', 'company', 'full_name']
    this.formDataFields.forEach(name => this.state[name] = '')
  }

  getSelectedHireDate() {
    if (!this.hireDatePickerInstance) { return null }
    const selectedDates = this.hireDatePickerInstance.selectedDates
    if (!selectedDates) { return null }
    return selectedDates[0]
  }

  getSelectedReturnDate() {
    if (!this.returnDatePickerInstance) { return null }
    const selectedDates = this.returnDatePickerInstance.selectedDates
    if (!selectedDates) { return null }
    return selectedDates[0]
  }

  hasSelectedHireDates() {
    return this.getSelectedHireDate() && this.getSelectedReturnDate
  }

  getSelectedHireDateObject() {
    const hireDate = this.getSelectedHireDate()
    if (!hireDate) { return null }
    return new Date(hireDate)
  }

  getSelectedReturnDateObject() {
    const returnDate = this.getSelectedReturnDate()
    if (!returnDate) { return null }
    return new Date(returnDate)
  }

  getFormattedHireDate() {
    const hireDate = this.getSelectedHireDate()
    if (!hireDate) { return null }
    return flatpickr.formatDate(hireDate, "Y-m-d")
  }

  getFormattedReturnDate() {
    const returnDate = this.getSelectedReturnDate()
    if (!returnDate) { return null }
    return flatpickr.formatDate(returnDate, "Y-m-d")
  }

  getHireDateStrings() {
    return getHireDateStrings(
      this.getSelectedHireDateObject(),
      this.getSelectedReturnDateObject()
    )
  }

  getFormattedHireSummaryArray() {
    if (!this.hasSelectedHireDates()) {
      throw new Error("getFormattedHireSummaryArray must not be called unless hire and return dates are selected. Check hasSelectedHireDates() first")
    }

    const {
      hireDate,
      returnDate,
      isHireDateToday,
      isHireDateTomorrow,
      isReturnDateTomorrow,
      hireDateString,
      returnDateString,
    } = this.getHireDateStrings()

    // Summary strings
    const bulletPoints = []
    let forWhen = ''
    let duration = ''

    // Single day hire
    if (this.isSameDayHire()) {
      duration = ' full day '
      if (this.state.isHalfDayAM) {
        duration = ' half day morning '
        bulletPoints.push('Pick-up from <strong>8am</strong>')
        bulletPoints.push('Drop off by <strong>12:30pm</strong>')
      } else if (this.state.isHalfDayPM) {
        duration = ' half day afternoon '
        bulletPoints.push('Pick-up from <strong>1pm</strong>')
        bulletPoints.push('Drop off by <strong>5:30pm</strong>')
      } else {
        bulletPoints.push('Pick-up from <strong>8am</strong>')
        bulletPoints.push('Drop off by <strong>5:30pm</strong>')
      }
      forWhen = ' on the '
      if (isHireDateToday) { forWhen = ' for today, the ' }
      if (isHireDateTomorrow) { forWhen = ' for tomorrow, the ' }
    }

    // Multi-day hire
    else {
      let daysOfHire = calcDaysHire(hireDate, returnDate)
      if (this.state.isHalfDayPM) {
        daysOfHire -= .5
        bulletPoints.push(`Pick-up from <strong>1pm</strong>${isHireDateToday ? ' today' : (isHireDateTomorrow ? ' tomorrow' : ` on the  ${hireDateString}`)}`)
      } else {
        bulletPoints.push('Pick-up from <strong>8am</strong>')
      }
      if (this.state.isHalfDayAM) {
        daysOfHire -= .5
        bulletPoints.push(`Drop-off before <strong>12:30pm</strong> ${isReturnDateTomorrow ? ' tomorrow' : ` on the ${returnDateString}`}`)
      } else {
        bulletPoints.push('Drop-off by <strong>5:30pm</strong>')
      }
      duration = ` ${daysOfHire} day `
      if (daysOfHire === 1 && this.state.isHalfDayAM && this.state.isHalfDayPM) {
        // Overnight rate
        duration = `${duration} overnight `
      }
      forWhen = ` from the ${hireDateString} to the `
    }

    return [
      `${this.props.product.name}<strong>${duration}hire</strong>${forWhen}${returnDateString}.`,
      ...bulletPoints
    ]
  }

  isSameDayHire() {
    const hireDate = this.getFormattedHireDate()
    return (hireDate && hireDate === this.getFormattedReturnDate())
  }

  isMultiDayHire() {
    return this.hasSelectedHireDates() && !this.isSameDayHire()
  }

  onHireDateChange(selectedDates) {
    // Always keep the min return ahead of the hire date
    this.returnDatePickerInstance.set("minDate", selectedDates[0])

    if (!this.userHasChosenReturnDate || this.getSelectedReturnDateObject() <= new Date(selectedDates[0])) {
      // Make the return day the same day by default, but
      // only if the user hasn't already set a return date.
      this.returnDatePickerInstance.setDate(selectedDates[0])
    }
  }

  onHireDateValueUpdate(selectedDates) {
    // state.hireDate isn't actually used, but
    // we need these calls to update the UI
    this.setState({ hireDate: selectedDates[0] })
  }

  onReturnDateChange(selectedDates) {
    this.userHasChosenReturnDate = true
  }

  onReturnDateValueUpdate(selectedDates) {
    // state.returnDate isn't actually used, but
    // we need these calls to update the UI
    this.setState({ returnDate: selectedDates[0] })

    if (this.isSameDayHire()) {
      // Turn off half day checkboxes
      this.setState({ isHalfDayAM: false, isHalfDayPM: false })
    }
  }

  onHireDateClose(selectedDates) {
    // console.log('onHireDateClose', selectedDates)
  }
  onReturnDateClose(selectedDates) {
    // console.log('onReturnDateClose', selectedDates)
  }

  handleSendRequest(e) {
    e.preventDefault()

    // Prevent form being sent twice
    if (this.state.sendingRequest) { return }

    const form = e.target
    const formData = new FormData(form)
    formData.append('product_id', this.props.product.id)
    const productRequest = formDataToObject(formData)
    productRequest.half_day_morning = productRequest.half_day_morning === 'on' ? true : false
    productRequest.half_day_afternoon = productRequest.half_day_afternoon === 'on' ? true : false

    this.setState({
      sendingRequest: true,
      sendErrorMsg: null,
      sendSuccessMsg: null
    })
    api.post(form.action, { product_request: productRequest })
      .then(
        (res) => {
          this.setState({ sendingRequest: false })
          if (res && res.errors) {
            return this.setState({ sendErrorMsg: res.errors[0] })
          }
          if (res.status_code !== 200) {
            return this.setState({ sendErrorMsg: `Something went wrong: Status ${res.status_code}` })
          }
          // Success
          this.setState({ sendingRequest: false, sendSuccessMsg: "Request sent" })
        })
  }

  componentDidMount() {
    // Get saved user fields from localStorage to populate the request form
    const savedData = JSON.parse(localStorage.getItem(REQUEST_ITEM_FORM_SAVED_CONTACT))
    if (savedData) {
      this.setState(pick(savedData, this.formDataFields))
    }
  }

  handleRequestFormInputChange(e) {
    const { name, value } = e.target
    this.setState({ [name]: value }, () => {
      // Save form info to localStorage so it's there next time they go to request an item
      localStorage.setItem(
        REQUEST_ITEM_FORM_SAVED_CONTACT,
        JSON.stringify(pick(this.state, this.formDataFields))
      )
    })
  }

  handleHalfDayCheckboxChange(e) {
    const name = e.target.getAttribute('name')

    if (!this.hasSelectedHireDates() || this.isSameDayHire()) {
      // Same day hire; Checkboxes toggle like radios inputs
      if (name === 'half_day_morning') {
        if (this.state.isHalfDayAM) {
          this.setState({ isHalfDayAM: false }) // User is clicking to delselect
        } else {
          this.setState({ isHalfDayAM: true, isHalfDayPM: false }) // toggle
        }
      }
      else if (name === 'half_day_afternoon') {
        if (this.state.isHalfDayPM) {
          this.setState({ isHalfDayPM: false }) // User is clicking to delselect
        } else {
          this.setState({ isHalfDayAM: false, isHalfDayPM: true }) // toggle
        }
      }
    }
    else {
      // Multi-day hire, normal checkbox functionality
      if (name === 'half_day_morning') {
        this.setState({ isHalfDayAM: !this.state.isHalfDayAM })
      }
      else if (name === 'half_day_afternoon') {
        this.setState({ isHalfDayPM: !this.state.isHalfDayPM })
      }
    }
  }

  handleStartDateClick(e, startDateOption) {
    e.preventDefault()

    // Leave dates blank if they clicked "Choose dates"
    if (startDateOption !== OPTION_CHOOSE) {
      let date = new Date()
      if (startDateOption === OPTION_TOMORROW) {
        date.setDate(date.getDate() + 1)
      }
      this.hireDatePickerInstance.setDate(date, true) // , "Y/m/d"
      this.returnDatePickerInstance.setDate(date, true) // , "Y/m/d"
    }

    // Now hide the buttons
    this.setState({ showStartDateButtons: false })
  }

  render() {

    const summaryArray = this.hasSelectedHireDates() ? this.getFormattedHireSummaryArray() : []

    let {
      hireDateString,
      returnDateString,
    } = this.isMultiDayHire() ? this.getHireDateStrings() : {}

    return (
      <form
        class="product-request-form"
        action={`/api/product_requests`}
        method="POST"
        name="product_request_form"
        onSubmit={this.handleSendRequest.bind(this)}
      >
        <h2>Request {this.props.product.name}</h2>
        <p>We try our best to respond to requests within the hour, but if urgent please also call Ben on <a href="tel:027 455 0514">027 455 0514</a> in addition to this request.</p>

        <input
          type="hidden"
          name="summary"
          value={this.hasSelectedHireDates() ? this.getFormattedHireSummaryArray()[0] : ''}
        />

        <div class="half-width-fieldsets">
          <fieldset>
            <label for="request_full_name">Full Name</label>
            <input
              type="text"
              name="full_name"
              id="request_full_name"
              placeholder="Joe Blogs"
              value={this.state.full_name}
              onChange={this.handleRequestFormInputChange.bind(this)}
            />
          </fieldset>
          <fieldset>
            <label for="request_company">Company (optional)</label>
            <input
              type="text"
              name="company"
              id="request_company"
              placeholder="My Company Ltd"
              value={this.state.company}
              onChange={this.handleRequestFormInputChange.bind(this)}
            />
          </fieldset>
        </div>

        <div class="half-width-fieldsets">
          <fieldset>
            <label for="request_phone">Phone</label>
            <input
              type="text"
              name="phone"
              id="request_phone"
              placeholder="027..."
              value={this.state.phone}
              onChange={this.handleRequestFormInputChange.bind(this)}
            />
          </fieldset>
          <fieldset>
            <label for="request_email">Email</label>
            <input
              type="text"
              name="email"
              id="request_email"
              placeholder="name@company.co.nz"
              value={this.state.email}
              onChange={this.handleRequestFormInputChange.bind(this)}
            />
          </fieldset>
        </div>

        <div class={cx("half-width-fieldsets", {
          hidden: this.state.showStartDateButtons
        })}>
          <fieldset>
            <label for="request_hire_date">Hire Date</label>
            <CalendarPicker
              onChange={this.onHireDateChange.bind(this)}
              onClose={this.onHireDateClose.bind(this)}
              minDate={this.minHireDate}
              maxDate={this.maxHireDate}
              class="input-clickable"
              placeholder="Choose a date"
              id="request_hire_date"
              name="hire_date"
              setInstanceRef={(ref) => this.hireDatePickerInstance = ref}
            />
          </fieldset>
          <fieldset>
            <label for="request_return_date">Return Date</label>
            <CalendarPicker
              onChange={this.onReturnDateChange.bind(this)}
              onValueUpdate={this.onReturnDateValueUpdate.bind(this)}
              onClose={this.onReturnDateClose.bind(this)}
              minDate={this.minHireDate}
              maxDate={this.maxHireDate}
              class="input-clickable"
              // defaultValue={this.state.returnDate}
              placeholder="Choose a date"
              id="request_return_date"
              name="return_date"
              setInstanceRef={(ref) => this.returnDatePickerInstance = ref}
            />
          </fieldset>
        </div>

        {this.state.showStartDateButtons && (
          <fieldset class="quick-select-date-buttons">
            <label>Hire date</label>
            <div>
              <button
                class="btn btn-block-outline"
                onClick={e => this.handleStartDateClick(e, OPTION_TODAY)}
              >Today</button>
              <button
                class="btn btn-block-outline"
                onClick={e => this.handleStartDateClick(e, OPTION_TOMORROW)}
              >Tomorrow</button>
            </div>
            <button
              class="btn btn-block-outline"
              onClick={e => this.handleStartDateClick(e, OPTION_CHOOSE)}
            >Choose dates</button>
          </fieldset>
        )}

        {!this.state.showStartDateButtons && (
          <>
            <label>Request a Half Day (Optional)</label>
            <div class={cx("half-width-fieldsets half-day-fieldsets", {
              reverse: this.hasSelectedHireDates() && !this.isSameDayHire()
            })}>
              <fieldset>
                <input
                  type="checkbox"
                  id="request-half-day-morning"
                  name="half_day_morning"
                  checked={this.state.isHalfDayAM}
                  onChange={this.handleHalfDayCheckboxChange.bind(this)}
                />
                <label htmlFor="request-half-day-morning">
                  <strong>Morning</strong>
                  {this.isMultiDayHire() && returnDateString}
                  <span> {this.isMultiDayHire() ? '(drop-off by 12:30pm)' : '(8am - 12:30pm)'}</span>
                </label>
              </fieldset>
              <fieldset>
                <input
                  type="checkbox"
                  id="request-half-day-afternoon"
                  name="half_day_afternoon"
                  checked={this.state.isHalfDayPM}
                  onChange={this.handleHalfDayCheckboxChange.bind(this)}
                />
                <label htmlFor="request-half-day-afternoon">
                  <strong>Afternoon</strong>
                  {this.isMultiDayHire() && hireDateString}
                  <span> {this.isMultiDayHire() ? '(pick-up from 1pm)' : '(1pm to 5:30pm)'}</span>
                </label>
              </fieldset>
            </div>
          </>
        )}

        <fieldset>
          <label for="request_message">Message (Optional)</label>
          <textarea
            type="text"
            name="customer_message"
            height="2"
            id="request_message"
            placeholder="E.g. If you want a late drop off, please request it here" />
        </fieldset>

        <div class={cx("request-msg-and-submit", {
          'half-width-fieldsets full-width-mobile full-width-small-desktop': this.hasSelectedHireDates()
        })}>
          {this.hasSelectedHireDates() && (
            <fieldset class="request-summary">
              <h3>Summary</h3>
              <p dangerouslySetInnerHTML={{ __html: summaryArray[0] }}></p>
              {summaryArray.length > 1 && (
                <ul>
                  {summaryArray.map((str, i) => {
                    if (i === 0) { return }
                    return (<li dangerouslySetInnerHTML={{ __html: str }}></li>)
                  })}
                </ul>
              )}
            </fieldset>
          )}
          <fieldset class="submit-request-fieldset">
            <div>
              {(this.state.sendErrorMsg || this.state.sendSuccessMsg) && (
                <>
                  {this.state.sendErrorMsg && (
                    <p class="form-error-message">{this.state.sendErrorMsg}</p>
                  )}
                  {this.state.sendSuccessMsg && (
                    <p class="form-success-message">{this.state.sendSuccessMsg}</p>
                  )}
                </>
              )}
            </div>
            <button type="submit" href="/" class="btn btn-black btn-large btn-fw">
              {this.state.sendingRequest ? 'Sending Request...' : 'Send Request'}
            </button>
          </fieldset>
        </div>
      </form>
    )
  }

}

export default ProductRequestForm
