import imageCompression from 'browser-image-compression'
import getS3FilenameFromName from '../utilities/getS3FilenameFromName'
import getImageDimensionsFromFile from '../utilities/getImageDimensionsFromFile'
import requireProps from "../utilities/requireProps"
import heic2any from "heic2any" // https://github.com/alexcorvi/heic2any/blob/master/docs/getting-started.md


/**
 * S3Uploader
 *
 * Example Props
 *  createSizes: [ // an array of max width/height images to create
 *    [300, .8],
 *    [1200, 1.2] // 1200px, 1.2MB
 *  ]
 *
 **/
class S3Uploader {

  constructor(props) {
    this.props = props

    requireProps(props, [
      'onStatusChange', // func
      'onUploadComplete', // func
      'requestPresignEndpoint', // string
    ])

    // Optional props
    if (!props.onEnqueueUpload) { props.onEnqueueUpload = () => {} }
    if (!props.onDenqueueUpload) { props.onDenqueueUpload = () => {} }
    if (!props.createSizes) { props.createSizes = [2400, 2] }

    this.uploadQueue = []
    this.totalImagesToUpload = 0
  }

  // Public Methods
  // --------------

  addFiles(files) {
    this.totalImagesToUpload += files.length
    files.forEach(file => this._enqueueUpload(file))
  }

  // Privte Methods
  // --------------

  _handleStatusChange(event, msg) {
    this.props.onStatusChange(event, msg)
  }

  async compressImage(fileToUpload, maxWidthOrHeight, maxSizeMB) {
    const exif = await imageCompression.getExifOrientation(fileToUpload)
    const compressionOptions = {
      maxSizeMB,
      maxWidthOrHeight,
      useWebWorker: true,
      maxIteration: 10,
      exifOrientation: exif,
      onProgress: (progress) => {
        this._handleStatusChange('progress', `Optimizing image ${progress}%`)
      }
    }
    return await imageCompression(fileToUpload, compressionOptions)
  }

  async getPresignedS3UploadUrl(filename) {
    // Get the s3 presigned url from our Rails backend
    const presignUrl = new URL(this.props.requestPresignEndpoint)
    presignUrl.searchParams.append('filename', filename)
    let presignResponse = await fetch(presignUrl, { method: 'GET' })
      .then(res => res.json())
      .catch(error => {
        console.log('Presign url fetch error', error)
      })
    if (presignResponse.errors && presignResponse.errors.length) {
      return this._handleStatusChange('error', presignResponse.errors[0])
    }
    return presignResponse.pkg.url
  }

  async uploadImageToS3(compressedImageFile, signedS3ImageUploadUrl, sizeData) {
    const s3Url = await fetch(signedS3ImageUploadUrl, {
      method: 'PUT',
      body: compressedImageFile
    })
    .then(response => {
      if (response.status === 200) {
        return [sizeData, response.url.split('?')[0]]
      }
      return null
    })
    .catch(error => console.log('s3 upload error', error))
    return s3Url
  }

  async getImageDimensions(imageFile) {
    const dimensions = await getImageDimensionsFromFile(imageFile)
      .catch((error) => {
        if (typeof error === 'string') {
          // Browser porbably not supported, so
          // carry on without image dimensions
          console.log('getImageDimensionsFromFile error', error);
          return error
        }
        throw new Error(error.toString())
      })

    if (!dimensions.width || !dimensions.height) {
      if (!dimensions.width) { dimensions.width = null }
      if (!dimensions.height) { dimensions.height = null }
      if (this.props.widthAndHeightRequired) {
        if (typeof dimensions === 'string') { return this._handleStatusChange('error', dimensions) }
        return this._handleStatusChange('error', "Something went wrong, your browser may not be supported")
      }
    }
    return dimensions
  }

  async handleFileUpload(fileToUpload) {
    this._handleStatusChange('progress', "Optimizing image")

    const uploadPromises = []
    let originalFilename = fileToUpload.name

    if (fileToUpload.type === 'image/heic') {
      // Convert from HEIC (iPhone) image
      this._handleStatusChange('progress', "Converting image from HEIC to Jpeg")
      fileToUpload = await heic2any({
        blob: fileToUpload,
        toType: "image/jpg",
      })
      .catch((e) => {
        console.error("heic2any: Error", e)
        // see error handling section
      })
      // Rename to be a jpg
      originalFilename = originalFilename.replace(/.HEIC/gi, '.jpg')
    }

    // Create all the image sizes that have
    // been requested via props.createSizes
    for (let i = 0; i < this.props.createSizes.length; i++) {
      const sizeData = this.props.createSizes[i]
      const maxPX = sizeData[0]
      const maxMB = sizeData[1]

      // Image compression
      const compressedImageFile = await this.compressImage(fileToUpload, maxPX, maxMB)
      // Get presigned upload url that allows us
      // to upload to Amazon S3 from the browser
      const signedS3ImageUploadUrl = await this.getPresignedS3UploadUrl(getS3FilenameFromName(originalFilename, `_${maxPX}`))
      uploadPromises.push(this.uploadImageToS3(compressedImageFile, signedS3ImageUploadUrl, sizeData))
    }

    const whatIsUploading = this.props.createSizes.length !== 1 ? `image (${this.props.createSizes.length} sizes)` : 'image'
    this._handleStatusChange(
      'progress',
      `Uploading ${whatIsUploading} ${(this.totalImagesToUpload - this.uploadQueue.length) + 1} of ${this.totalImagesToUpload}...`
    )

    // Finally, deqeue the upload so that others may start
    Promise.all(uploadPromises).then(async uploadArray => {
      const imagesBySize = {}
      uploadArray.forEach(upload => {
        const sizeData = upload[0]
        const imgUrl = upload[1]
        imagesBySize[sizeData[0]] = imgUrl
      })
      const dimensions = await this.getImageDimensions(fileToUpload)
      this._handleStatusChange('progress', null)
      this.props.onUploadComplete({
        width: dimensions.width,
        height: dimensions.height,
        imagesBySize
      })
      this._dequeueUpload()
    })
  }

  _enqueueUpload(imageFile) {
    this.uploadQueue.push(imageFile)
    if (this.uploadQueue.length <= 1) {
      // this is the only queued upload, so start the queue
      this.handleFileUpload(imageFile)
    }
    this.props.onEnqueueUpload(imageFile)
  }

  _dequeueUpload() {
    // take the finished upload off the queue
    this.uploadQueue.shift()
    if (this.uploadQueue.length > 0) {
      this.handleFileUpload(this.uploadQueue[0])
    } else {
      // Finished uplodaer, reset "total to upload" count
      this.totalImagesToUpload = 0
    }
    this.props.onDenqueueUpload()
  }
}

export default S3Uploader
