import { Component, h } from "preact"
import cx from 'classnames'
import PropTypes from 'prop-types'
import requireProps from "../utilities/requireProps"


class FileDropper extends Component {

  constructor(props) {
    super()

    requireProps(props, [
      'onFilesDrop' // function
    ])

    this.dropzoneEl = null
    this.dropzoneInput = null

    this.dragOverClass = "FileDropper--over"

    this.state = {
      uploading: false
    }
  }

  handleFilesDrop(files) {
    this.props.onFilesDrop(files)
  }

  handleDropzoneInputClick() {
    this.dropzoneInput.click()
  }

  handleDropzoneInputChange() {
    if (this.dropzoneInput.files.length) {
      this.handleFilesDrop(this.dropzoneInput.files)
    }
  }

  handleDragOver(e) {
    e.preventDefault()
    this.dropzoneEl.classList.add(this.dragOverClass)
  }

  handleDrop(e) {
    e.preventDefault()

    if (e.dataTransfer.files.length) {
      this.dropzoneInput.files = e.dataTransfer.files
      this.handleFilesDrop(this.dropzoneInput.files)
    }

    this.removeDragOverClass()
  }

  removeDragOverClass() {
    this.dropzoneEl.classList.remove(this.dragOverClass)
  }

  render() {
    return (
      <div
        class={cx("FileDropper", this.props.class, {
          ["FileDropper-uploading"]: this.state.uploading
        })}
        ref={ref => this.dropzoneEl = ref} onClick={this.handleDropzoneInputClick.bind(this)}
        onDragOver={this.handleDragOver.bind(this)}
        onDrop={this.handleDrop.bind(this)}
        onDragLeave={this.removeDragOverClass.bind(this)}
        onDragEnd={this.removeDragOverClass.bind(this)}
      >
        <span class="FileDropper__prompt">
          Drop file here or click to upload
        </span>
        <input
          type="file"
          name="myFile"
          class="FileDropper__input"
          multiple={this.props.multiple}
          onChange={this.handleDropzoneInputChange.bind(this)}
          ref={ref => this.dropzoneInput = ref}
        />
        {/* <i className="FileDropper__spinner icon-spinner9 animate-spin"></i> */}
      </div>
    )
  }
}

FileDropper.defaultProps = {
  multiple: true
}
FileDropper.propTypes = {
  class: PropTypes.string,
  multiple: PropTypes.bool,
  onFilesDrop: PropTypes.func.isRequired,
}

export default FileDropper
