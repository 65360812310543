import { Component, h, Fragment } from "preact"
import PropTypes from 'prop-types'
import requireProps from "../utilities/requireProps"
import ProductImageThumbnails from "./ProductImageThumbnails"


class ProductImageCarousel extends Component {

  constructor(props) {
    super()

    requireProps(props, [
      'product',
      'productPhotos'
    ])

    let activeImageId = null
    props.productPhotos.forEach(photo => {
      if (photo.id === props.product.featureProductPhotoId) {
        activeImageId = photo.id
      }
    })

    this.state = {
      activeImageId,
      timeToPreload: false
    }
    this._imgPreloadTimeout = null
  }

  getActiveImage() {
    return this.props.productPhotos.find(photo => photo.id === this.state.activeImageId)
  }

  handleThumbnailClick(productPhoto) {
    this.setState({ activeImageId: productPhoto.id })
  }

  componentDidMount() {
    console.info('Carousel mounted')
    this._mounted = true
    this._imgPreloadTimeout = setTimeout(() => {
      // Delay img preloading by X seconds after
      // the brower has rendered this component
      this.setState({ timeToPreload: true })
      console.info('Image preload has begun')
    }, 1000)
  }
  componentWillUnmount() { this._mounted = false }

  render() {
    const activeImage = this.getActiveImage()
    // Values come from using this tool https://ausi.github.io/respimagelint/
    const sizesAttr = `
      (min-width: 1120px) 600px,
      (min-width: 761px) calc(100vw - 500px),
      (min-width: 601px) calc(100vw - 80px),
      (min-width: 336px) calc(100vw - 40px),
      300px
    `
    return (
      <div>
        {/* TODO: Add left/right buttons */}
        {this.props.productPhotos.length > 0 && activeImage ? (
          <img
            src={activeImage.imageUrl900}
            srcset={`
              ${activeImage.imageUrl150} 150w,
              ${activeImage.imageUrl300} 300w,
              ${activeImage.imageUrl600} 600w,
              ${activeImage.imageUrl900} 900w,
              ${activeImage.imageUrl1200} 1200w,
              ${activeImage.imageUrl1500} 1500w,
              ${activeImage.imageUrl1800} 1800w
            `}
            sizes={sizesAttr}
            width={activeImage.width}
            height={activeImage.height}
            class="product-photos__active-img"
           />
        ) : (
          <div class="product-photos__placeholder-img">
            Images coming soon
          </div>
        )}

        {/* Preload images */}
        {this.props.productPhotos.length > 1 && (
          <>
            <ProductImageThumbnails
              productPhotos={this.props.productPhotos}
              highlightedImageId={this.state.activeImageId}
              onThumbnailClick={this.handleThumbnailClick.bind(this)}
            />

            {/* Preload images so they show up when the user clicks them */ }
            {this.state.timeToPreload && (
              < div class="product-photos__preloading-images">
              {this.props.productPhotos.map(photo => (
                <img
                  src={photo.imageUrl900}
                  srcset={`
                    ${photo.imageUrl150} 150w,
                    ${photo.imageUrl300} 300w,
                    ${photo.imageUrl600} 600w,
                    ${photo.imageUrl900} 900w,
                    ${photo.imageUrl1200} 1200w,
                    ${photo.imageUrl1500} 1500w,
                    ${photo.imageUrl1800} 1800w
                  `}
                  sizes={sizesAttr}
                />
              ))}
            </div>
            )}
          </>
        )}
      </div>
    )
  }
}

ProductImageCarousel.defaultProps = {}

ProductImageCarousel.propTypes = {
  product: PropTypes.object.isRequired,
  productPhotos: PropTypes.array.isRequired,
}

export default ProductImageCarousel
