import { h } from "preact"
import PropTypes from 'prop-types'


function TabbedContent(props) {

  const defaultActiveTabId = props.defaultActiveTabId || props.children[0].props.id

  return (
    <div className="tabbed-content">
      {props.children.map((tabPanel, index) => {
        return (
          <React.Fragment key={`tab-button-${index}`}>
            <input
              type="radio" name="tabset" id={`tab-${index}`}
              defaultChecked={defaultActiveTabId === tabPanel.props.id}
              value={tabPanel.props.id}
              onChange={() => props.onChange && props.onChange(tabPanel.props.id)}
              aria-controls={tabPanel.props.id} />
            <label htmlFor={`tab-${index}`}>{tabPanel.props.name}</label>
          </React.Fragment>
        )
      })}

      <div className="tab-panels">
        {props.children}
      </div>
    </div>
  )
}

TabbedContent.propTypes = {
  children: PropTypes.array.isRequired,
  defaultActiveTabId: PropTypes.string,
  onChange: PropTypes.func,
}

export default TabbedContent
