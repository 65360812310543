import { h } from "preact"
import requireProps from "../utilities/requireProps"
import css from './ProductListMinimal.module.scss'
import cx from "classnames"

function ProductListMinimal(props) {

  requireProps(props, [
    'products',
  ])
  const { products } = props

  return (
    <ul class={cx(css.list, props.class)}>
      {products.map(product => (
        <li class={css.listItem}>
          <div>
            {product.featureImageUrl150 ? (
              <img src={product.featureImageUrl150} />
            ) : (
              <i class="icon-images text-grey"></i>
            )}
          </div>
          <span>{product.name}</span>
        </li>
      ))}
    </ul>
  )

}

export default ProductListMinimal
