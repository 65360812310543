import { Component, h, Fragment } from "preact"
import PropTypes from 'prop-types'
import requireProps from "../utilities/requireProps"
import api from "../utilities/api"
import cx from "classnames"


class ProductCategoryItem extends Component {

  constructor(props) {
    super()

    requireProps(props, [
      'apiEndpoint', // string
      'categoryName', // string
      'productCategoryId', // number
    ])

    this.state = { deleteError: false, isDeleted: false }
  }

  handleCategoryDelete() {
    this.setState({ deleteError: false })
    api.delete(`${this.props.apiEndpoint}/product_categories/${this.props.productCategoryId}`)
      .then(
        (res) => {
          if (res && res.errors) {
            console.error(`Product Category didn't delete: Status ${res.errors[0]}`)
            return this.setState({ deleteError: true })
          }
          if (res.status_code !== 200) {
            console.error(`Product Category didn't delete: Status ${res.status_code}`)
            return this.setState({ deleteError: true })
          }
          // Success
          this.setState({ deleteError: false, isDeleted: true })
          // TODO: Delete parent div and unmount/destroy component
        })
  }

  render(props) {
    if (this.state.isDeleted) { return '' }
    return (
      <>
        {props.categoryName}
        <i
          class={cx('icon-cancel', 'product-edit__category-delete', {
            'product-edit__category-delete-error': this.state.deleteError
          })}
          onClick={this.handleCategoryDelete.bind(this)}></i>
      </>
    )
  }
}

ProductCategoryItem.defaultProps = {}

ProductCategoryItem.propTypes = {
  apiEndpoint: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
  productCategoryId: PropTypes.number.isRequired,
}

export default ProductCategoryItem
